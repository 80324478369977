import General from './General'
import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {

  static updateAccount(data){
    data = JSON.parse(JSON.stringify(data));
    return FetchHelper.patch(`${window.Api.Admins}/${data.user.id}`, data)
  }

  static addAdmin(admin){
    let data = JSON.parse(JSON.stringify(admin));
    return FetchHelper.post(window.Api.Admins, data)
  }

  static updateAdmin(admin){
    let data = JSON.parse(JSON.stringify(admin));
    return FetchHelper.patch(`${window.Api.Admins}/${admin.user.id}`, data)
  }

  static deleteAdmin(admin){
    return FetchHelper.delete(`${window.Api.Admins}/${admin.user.id}`)
  }

  static addParty(party){
    let data = JSON.parse(JSON.stringify(party));
    if(data.authorisation){
      data.authorisation = data.authorisation.id
    }
    return FetchHelper.post(window.Api.Parties, data)
  }

  static getForms(authorised){
    return FetchHelper.get(`${window.Api.Forms}?authorised=${authorised}&paginated=false`)
  }

  static getTemplates(){
    return FetchHelper.get(`${window.Api.Submissions}?template=true&paginated=false`)
  }

  static submitDeclaration(declaration){
    let data = JSON.parse(JSON.stringify(declaration))
    return FetchHelper.post(window.Api.Submissions, data)
  }

  static getSubmission(submissionId){
    return FetchHelper.get(`${window.Api.Submissions}/${submissionId}`)
  }

  static updateDeclaration(submissionId, declaration){
    let data = JSON.parse(JSON.stringify(declaration))
    return FetchHelper.patch(`${window.Api.Submissions}/${submissionId}`, data)
  }

  static deleteSubmission(submissionId){
    return FetchHelper.delete(`${window.Api.Submissions}/${submissionId}`)
  }

  static getCodes(){
    return FetchHelper.get(`${window.Api.Codes}`)
  }

  static getParty(partyId){
    return FetchHelper.get(`${window.Api.Parties}/${partyId}`)
  }

  static updateParty(party){
    let data = JSON.parse(JSON.stringify(party))
    if(data.type !== "representative"){
      delete data.indirect_representative
    }
    return FetchHelper.patch(`${window.Api.Parties}/${party.id}`, data)
  }

  static deleteParty(party){
    return FetchHelper.delete(`${window.Api.Parties}/${party.id}`)
  }

  static addCompanyMember(companyMember){
    let data = JSON.parse(JSON.stringify(companyMember));
    return FetchHelper.post(window.Api.CompanyMembers, data)
  }

  static updateCompanyMember(companyMember){
    let data = JSON.parse(JSON.stringify(companyMember));
    return FetchHelper.patch(`${window.Api.CompanyMembers}/${companyMember.user.id}`, data)
  }

  static addAuthorisation(authorisation){
    let data = JSON.parse(JSON.stringify(authorisation));
    return FetchHelper.post(window.Api.Authorisations, data)
  }

  static updateAuthorisation(authorisation){
    let data = JSON.parse(JSON.stringify(authorisation));
    return FetchHelper.post(`${window.Api.Authorisations}/${authorisation.id}`, data)
  }

  static getList(listId){
    return FetchHelper.get(`${window.Api.Lists}/${listId}`)
  }

  static getListCodes(listKey){
    return FetchHelper.get(`${window.Api.Codes}?list_id=${listKey}`)
  }

  static getOverallStats(){
    return FetchHelper.get(`${window.Api.Stats}?overall=true`)
  }

  static _parseGuarantee(references){
    let parsedReferences = []
    references.map(reference => {
      reference.currency = reference.currency ? reference.currency.id : null
      parsedReferences.unshift(Object.assign({}, reference))
    })
    return parsedReferences
  }

  static addGuarantee(guarantee){
    let data = JSON.parse(JSON.stringify(guarantee));
    data.type = data.type.id
    data.references = Backend._parseGuarantee(data.references)
    return FetchHelper.post(window.Api.Guarantees, data)
  }

  static updateGuarantee(guarantee){
    let data = JSON.parse(JSON.stringify(guarantee));
    data.references = Backend._parseGuarantee(data.references)
    return FetchHelper.patch(`${window.Api.Guarantees}/${guarantee.id}`, data)
  }

  static getSubmissionShipmentItems(submission){
    return FetchHelper.get(`${window.Api.ShipmentItems}?submission_id=${submission.id}`)
  }

  static _parseCommodity(commodity){
    commodity.procedure = commodity.procedure ? commodity.procedure.id : null
    commodity.previous_procedure = commodity.previous_procedure ? commodity.previous_procedure.id : null
    commodity.preference = commodity.preference ? commodity.preference.id : null
    commodity.previous_document_type = commodity.previous_document_type ? commodity.previous_document_type.id : null
    commodity.commodity_code.taric = commodity.commodity_code.taric ? commodity.commodity_code.taric.id : null
    commodity.valuation.preference = commodity.valuation.preference ? commodity.valuation.preference.id : null
    commodity.valuation.currency = commodity.valuation.currency ? commodity.valuation.currency.id : null
    commodity.valuation.indicator = commodity.valuation.indicator ? commodity.valuation.indicator.id : null
    commodity.valuation.method = commodity.valuation.method ? commodity.valuation.method.id : null

    if(commodity.additional_procedures){
      commodity.additional_procedures = commodity.additional_procedures.map(additionalProcedure => {
        additionalProcedure.code = additionalProcedure.code ? additionalProcedure.code.id : null
        return additionalProcedure
      })
    }else{
      commodity.additional_procedures = []
    }

    if(commodity.documents){
      commodity.documents = commodity.documents.map(document => {
        document.code = document.code ? document.code.id : null
        return document
      })
    }else{
      commodity.documents = []
    }

    if(commodity.additional_information){
      commodity.additional_information = commodity.additional_information.map(additionalInformation => {
        additionalInformation.code = additionalInformation.code ? additionalInformation.code.id : null
        return additionalInformation
      })
    }else{
      commodity.additional_information = []
    }

    if(commodity.commodity_code.national_additional_codes){
      commodity.commodity_code.national_additional_codes = commodity.commodity_code.national_additional_codes.map(additionalCode => {
        additionalCode.code = additionalCode.code ? additionalCode.code.id : null
        return additionalCode
      })
    }else{
      commodity.commodity_code.national_additional_codes = []
    }

    if(commodity.commodity_code.taric_additional_codes){
      commodity.commodity_code.taric_additional_codes = commodity.commodity_code.taric_additional_codes.map(additionalCode => {
        additionalCode.code = additionalCode.code ? additionalCode.code.id : null
        return additionalCode
      })
    }else{
      commodity.commodity_code.taric_additional_codes = []
    }

    if(commodity.packages){
      commodity.packages = commodity.packages.map(package_ => {
        package_.type = package_.type ? package_.type.id : null
        return package_
      })
    }else{
      commodity.packages = []
    }

    if(commodity.valuation.operations){
      commodity.valuation.operations = commodity.valuation.operations.map(operation => {
        operation.code = operation.code ? operation.code.id : null
        return operation
      })
    }else{
      commodity.valuation.operations = []
    }

    return commodity
  }

  static addCommodity(commodity){
    let data = JSON.parse(JSON.stringify(commodity));
    data = Backend._parseCommodity(data)
    return FetchHelper.post(window.Api.Items, data)
  }

  static updateCommodity(commodity){
    let data = JSON.parse(JSON.stringify(commodity));
    return FetchHelper.patch(`${window.Api.Items}/${commodity.id}`, data)
  }

  static createShipmentItem(shipmentItem){
    let data = JSON.parse(JSON.stringify(shipmentItem));
    data = Backend._parseCommodity(data)
    return FetchHelper.post(window.Api.ShipmentItems, data)
  }

  static _parseAdditionalInformation(additionalInformations){
    return additionalInformations.map(additionalInformation => {
      additionalInformation.type = additionalInformation.type.id
      return additionalInformation
    })
  }

  static _parseSupportingDocuments(supportingDocuments){
    return supportingDocuments.map(supportingDocument => {
      supportingDocument.file = supportingDocument.file ? supportingDocument.file.id : null
      return supportingDocument
    })
  }

  static submitDocuments(data){
    data = JSON.parse(JSON.stringify(data));
    data.additional_information = Backend._parseAdditionalInformation(data.additional_information)
    data.supporting_documents = Backend._parseSupportingDocuments(data.supporting_documents)
    return FetchHelper.post(window.Api.DocumentsRequest, data)
  }

  static addAccount(data){
    data = JSON.parse(JSON.stringify(data));
    return FetchHelper.post(window.Api.Companies, data)
  }

  static deleteAccount(account){
    return FetchHelper.delete(`${window.Api.Companies}/${account.id}`)
  }

  static getProduct(id){
    return FetchHelper.get(`${window.Api.Items}/${id}`)
  }

  static addCode(data){
    return FetchHelper.post(window.Api.Codes, data)
  }

  static updateCode(code){
    return FetchHelper.patch(`${window.Api.Codes}/${code.id}`, code)
  }

  static deleteCode(code){
    return FetchHelper.delete(`${window.Api.Codes}/${code.id}`)
  }

  static updateField(field){
    return FetchHelper.patch(`${window.Api.Fields}/${field.id}`, field)
  }

  static addRestrictedCode(data){
    data = General.clone(data)
    data.form = data.form.id
    data.taric = data.taric.id
    return FetchHelper.post(window.Api.RestrictedCodes, data)
  }

  static editRestrictedCode(code){
    return FetchHelper.patch(`${window.Api.RestrictedCodes}/${code.id}`, code)
  }

  static deleteRestrictedCode(code){
    return FetchHelper.delete(`${window.Api.RestrictedCodes}/${code.id}`)
  }

  static getFields(subType, formatTypes){
    return FetchHelper.get(`${window.Api.Fields}?form_subtype=${subType}&format_types=${formatTypes}&order_by=title&paginated=false`)
  }
}
