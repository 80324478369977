import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";

export const ROUTE_FILTERS = [
    {
      label: 'Red',
      value: 'red',
    },
    {
      label: 'Orange',
      value: 'orange',
    },
    {
      label: 'Yellow',
      value: 'yellow',
    }
]

export default class RestrictedCode extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      restrictedCode: props.restrictedCode ? General.clone(props.restrictedCode) : {
        routes: []
      }
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _isFormValid(){
    let { restrictedCode } = this.state
    let {
      form,
      taric,
      routes,
    } = restrictedCode

    let error = null
    if(!taric){
      error = "Please select a TARIC Code"
    }
    else if(!form){
      error = "Please select a form"
    }
    else if(routes.length == 0){
      error = "Please select one or more routes"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _handleSave(){
    let { restrictedCode } = this.state
    if(!this._isFormValid()){
      return
    }

    this.setState({ isLoading: true })
    this._handleBackend(restrictedCode)
    .then(code => {
      this.setState({ isLoading: false })
      Notify.success("Restricted Code Updated")
      this.props.onUpdated(code)
    })
    .catch(error => {
      this.setState({ isLoading: false })
      Notify.success(error.message)
    })
  }

  _handleBackend(data){
    if(data.id){
      return Backend.editRestrictedCode(data)
    }
    return Backend.addRestrictedCode(data)
  }

  render() {
    let {
      show,
      isLoading,
      restrictedCode,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Restricted Code
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="fv-row mb-7 fv-plugins-icon-container"
            bis_skin_checked="1"
          >
            <label className="required fw-semibold fs-6 mb-2">TARIC</label>
            <AsyncSelect
              placeholder={"TARIC"}
              endpoint={window.Api.Codes}
              isClearable={true}
              orderBy={"value"}
              filter={`list_key=NomenclatureCode`}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              value={General.getSingleAsyncOption(restrictedCode.taric, "value")}
              getOptions={options => {
                  return General.getAsyncOptions(options, "value")
              }}
              disabled={restrictedCode.id}
              onSelected={option => {
                restrictedCode.taric = option
                this.setState({ restrictedCode })
              }}
            />
          </div>

          <div
            className="fv-row mb-7 fv-plugins-icon-container"
            bis_skin_checked="1"
          >
            <label className="required fw-semibold fs-6 mb-2">Form</label>
            <AsyncSelect
              placeholder={"Please select a form"}
              endpoint={window.Api.Forms}
              orderBy={"name"}
              isClearable={false}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              value={General.getSingleAsyncOption(restrictedCode.form)}
              getOptions={options => {
                  return General.getAsyncOptions(options)
              }}
              disabled={restrictedCode.id}
              onSelected={form => {
                restrictedCode.form = form
                this.setState({ restrictedCode })
              }}
            />
          </div>

          <div className="form-group row mb-10">
            <label className="required form-label ">Routes</label>
            <Select
              isClearable={true}
              isMulti={true}
              value={restrictedCode.routes.map(route => {
                return {
                  label: General.toTitleCase(route),
                  value: route
                }
              })}
              options={ROUTE_FILTERS}
              placeholder="Routes"
              getOptionLabel={route => route.label}
              getOptionValue={route => route.value}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              onSelected={result => {
                restrictedCode.routes = result?.map(option => option.value) || []
                this.setState({ restrictedCode })
              }}
            />
          </div>

        </Modal.Body>

        <Modal.Footer
          className='scroll-y'
        >

          <div className='text-right pt-5'>
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-primary"
              disabled={isLoading}
              onClick={() => this._handleSave()}
            >
              Save
            </button>
          </div>


        </Modal.Footer>

      </Modal>
    )
  }
}
