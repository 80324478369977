import React from "react"
import MockData from "../utils/MockData";
import LocationInput from "./components/common/LocationInput";
import AccountDetails from "./components/register/AccountDetails";
import DeclarantInfo from "./components/register/DeclarantInfo";
import RepresentativeInfo from "./components/register/RepresentativeInfo";
import {Link} from "react-router-dom";
import AuthManager from "../utils/AuthManager";
import Email from "../utils/Email";
import Notify from "../utils/Notify";

const MODE_SIGNIN = "signin"
const MODE_FORGOT_PASSWORD = "forgot_password"
const MODE_RESET_PASSWORD = "reset_password"

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: MODE_SIGNIN,
      activeTabIndex: 0,
      data: {
        name: "",
        members: [
          {
            user: {
              first_name: "",
              last_name: "",
              email: "",
              password: "",
            }
          }
        ]
      }
    }
  }

  componentDidMount() {
    document.getElementById("root").className = "bg-body"
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    if (!Email.isValid(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    if(mode === MODE_RESET_PASSWORD){
      if(!code){
        this.setState({ error: 'Please enter a valid code' })
        return false
      }
      if(password.length < 6){
        this.setState({ error: 'Password must be at least 6 characters' })
        return false
      }
    }

    return true
  }

  _handleResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, code } = this.state

    this.setState({ isLoading: true })
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: "",
          password: "",
          confirmPassword: "",
        })
        Notify.success("Password reset successfully!")
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: error.message
        })
      })

  }

  _handleRequestResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, confirmPassword } = this.state

    this.setState({ isLoading: true })
    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: "",
          confirmPassword: "",
        })
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: error.message
        })
      })
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password)
      .then(() => {
        let url = "/"
        window.location = url
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: error?.message
        })
      })
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
      signUpStep,
      showImagePicker,
      pkg
    } = this.state

    return (
      <>
        <div className="d-flex flex-column flex-root">

          <div className="d-flex flex-column flex-lg-row flex-column-fluid">

            <div
              className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bgi-no-repeat bgi-size-cover"
              style={{backgroundImage: "url('https://images.pexels.com/photos/3057960/pexels-photo-3057960.jpeg?auto=compress&amp;cs=tinysrgb&amp;w=1260&amp;h=750&amp;dpr=2')"}}
            >

              <div
                className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px h-100"
                style={{backgroundColor: "rgba(0, 0, 0, .6)"}}
              >

                <div
                  className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20"
                  style={{justifyContent: 'center'}}
                >

                  <a href="#" className="py-9 mb-5">
                    <img alt="Logo" src={require("../assets/media/logos/light-logo.svg").default} className="h-90px logo"/>
                  </a>

                </div>


              </div>

            </div>

            <div className="d-flex flex-column flex-lg-row-fluid py-10">
              <div className="d-flex flex-center flex-column flex-column-fluid">
                <div
                  className="w-lg-500px p-10 p-lg-15 mx-auto"
                  style={{
                    display: mode === MODE_SIGNIN ? "block" : "none"
                  }}
                >
                  <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form">
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Sign In</h1>
                    </div>

                    <div className="fv-row mb-10">
                      <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={e => this.setState({email: e.target.value, error: null})}
                        autoFocus
                      />
                    </div>

                    <div className="fv-row mb-10">
                      <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                        <a
                          href="#"
                          className="link-primary fs-6 fw-bolder"
                          onClick={() => this.setState({email: "", mode: MODE_FORGOT_PASSWORD, error: null})}
                        >
                          Forgot Password ?
                        </a>
                      </div>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        name="password"
                        autoComplete="off"
                        value={password}
                        onChange={e => this.setState({password: e.target.value, error: null})}
                      />
                    </div>

                    <div className="text-center">
                      <button
                        id="kt_sign_in_submit"
                        className="btn btn-lg btn-primary w-100 mb-5"
                        onClick={e => this._handleLogInClicked(e)}
                        disabled={isLoading}
                      >
                        <span className="indicator-label">Sign In</span>
                        <span className="indicator-progress">
                          Please wait...
										      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>

                  </form>
                </div>

                <div
                  className="w-lg-500px p-10 p-lg-15 mx-auto"
                  style={{
                    display: mode === MODE_FORGOT_PASSWORD ? "block" : "none"
                  }}
                >
                  <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form">
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Forgotten Password ?</h1>
                      <div className="text-gray-400 fw-bold fs-4">Enter your email to reset your password.</div>
                    </div>

                    <div className="fv-row mb-10">
                      <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={e => this.setState({email: e.target.value, error: null})}
                        autoFocus
                      />
                    </div>

                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                      <button
                        type="submit"
                        id="kt_password_reset_submit"
                        className="btn btn-lg btn-primary fw-bolder me-4"
                        onClick={e => this._handleRequestResetPassword(e)}
                        disabled={isLoading}
                      >
                        <span className="indicator-label">Submit</span>
                        <span className="indicator-progress">
                          Please wait...
										      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      <a
                        className="btn btn-lg btn-light-primary fw-bolder"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({email: "", password: "", mode: MODE_SIGNIN, error: null})
                        }}
                      >
                        Cancel
                      </a>
                    </div>

                  </form>
                </div>

                <div
                  className="w-lg-500px p-10 p-lg-15 mx-auto"
                  style={{
                    display: mode === MODE_RESET_PASSWORD ? "block" : "none"
                  }}
                >
                  <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form">
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Reset Password</h1>
                      <div className="text-gray-400 fw-bold fs-4">
                        A verification code was sent to your email, please enter it below along with your new password
                      </div>
                    </div>

                    <div className="fv-row mb-10">
                      <label className="form-label fs-6 fw-bolder text-dark">Code</label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        name="email"
                        autoComplete="off"
                        value={code}
                        onChange={e => this.setState({code: e.target.value, error: null})}
                      />
                    </div>

                    <div className="fv-row mb-10">
                      <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        name="password"
                        autoComplete="off"
                        value={password}
                        onChange={e => this.setState({password: e.target.value, error: null})}
                      />
                    </div>

                    <div className="fv-row mb-10">
                      <label className="form-label fw-bolder text-dark fs-6 mb-0">Confirm Password</label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        name="password"
                        autoComplete="off"
                        value={confirmPassword}
                        onChange={e => this.setState({confirmPassword: e.target.value, error: null})}
                      />
                    </div>

                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                      <button
                        type="submit"
                        id="kt_password_reset_submit"
                        className="btn btn-lg btn-primary fw-bolder me-4"
                        onClick={e => this._handleResetPassword(e)}
                        disabled={isLoading}
                      >
                        <span className="indicator-label">Submit</span>
                        <span className="indicator-progress">
                          Please wait...
										      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      <a
                        className="btn btn-lg btn-light-primary fw-bolder"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({email: "", password: "", mode: MODE_SIGNIN, error: null})
                        }}
                      >
                        Cancel
                      </a>
                    </div>

                  </form>
                </div>

              </div>

            </div>

          </div>

        </div>
      </>
    )
  }
}