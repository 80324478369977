import React from "react"

const STAT_VISUAL = require('../../../assets/media/svg/shapes/abstract-2.svg')

export default class StatCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      variation: props.variation,
      title: props.title,
      className: props.className,
      count: props.count,
      percentage: props.percentage,
    }
  }

  _renderNumberCard(){
    return (
      <div className="col-sm-6 col-xl-4">
        <div className="card h-lg-100 mb-3">
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            <div className="m-0">
              <span className={`svg-icon svg-icon-2hx svg-icon-${this.state.className}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                     version="1.1" style={{verticalAlign: 'top'}}>
                  <circle fill="currentColor" cx="12" cy="12" r="8"/>
                </svg>
              </span>
              <span className={`fw-bold fs-2x text-${this.state.className}`} style={{lineHeight:1.3}}>{this.state.count}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderDetailedCard(){
    return (
      <div className="col-sm-3 col-xl-3">
        <div
          className="card bgi-no-repeat card-xl-stretch mb-8"
          style={{backgroundPosition: 'right top', backgroundSize: '30% auto', backgroundImage: `url(${STAT_VISUAL.default})`}}
        >
          <div className="card-body">
            <a href="#" className="card-title fw-bold text-muted text-hover-primary fs-7">
              {this.state.title}
            </a>
            <div className="fw-bold text-primary my-3 fs-2x lh-1 ls-n2">
              {this.state.count}
              {
                this.state.percentage &&
                <span className=" ps-3 text-dark fw-bold fs-7 m-0">
                  <span className={`badge badge-light-${this.state.className} badge-sm fs-base`}>
                    <span className={`svg-icon svg-icon-5 svg-icon-${this.state.className} ms-n1`}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor"/>
                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor"/>
                      </svg>
                    </span>
                    {(this.state.percentage*100).toFixed(2)}%
                  </span>
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {
      variation
    } = this.state

    return (
      <>
        {
          variation === "detail" ?
            this._renderDetailedCard()
          :
            this._renderNumberCard()
        }
      </>
    )
  }

}