import React from "react"

import AccountsTable from "./components/tables/AccountsTable";

export default class Accounts extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <AccountsTable
              title={"Accounts"}
            />
          </div>
        </div>
      </>
    )
  }

}