import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class Confirmation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer
          className='scroll-y'
        >

          <div className='text-right pt-5'>
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-danger"
              onClick={() => this.props.onConfirm()}
            >
              Delete
            </button>
          </div>


        </Modal.Footer>

      </Modal>
    )
  }
}
