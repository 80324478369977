import React from "react"

import RestrictedCodesTable from "./components/tables/RestrictedCodesTable";

export default class RestrictedCodes extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <RestrictedCodesTable
              title={"Restricted Codes"}
            />
          </div>
        </div>
      </>
    )
  }

}
