import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class Code extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      code: props.code ? { ...props.code } : { list: props.listId }
    }
  }

  _handleChange(e) {
    let { code } = this.state
    code[e.target.name] = e.target.value || null
    this.setState({ code, error: null })
  }

  _isFormValid(){
    let {
      code
    } = this.state

    let error = null
    if(!code.name){
      error = "Please specify a valid name"
    }else if(!code.name){
      error = "Please specify a valid name"
    }
    else if(!code.value){
      error = "Please specify a valid value"
    }

    if(error){
      Notify.error(error)
    }

    return error == null
  }

  _save(){
    let { code } = this.state

    if(!this._isFormValid()){
      return
    }
    this.setState({loading: true})
    this._handleBackend(code)
    .then(party => {
      this.setState({loading: false})
      Notify.success("Code Saved!")
      this.props.onUpdated()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }



  _handleBackend(code) {
    if(code.id){
      return Backend.updateCode(code)
    }
    return Backend.addCode(code)
  }

  render() {
    let {
      show,
      code,
      loading,
    } = this.state

    let title = code.id ? "Edit Code" : "Add Code"

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll"
              data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px"
              style={{maxHeight: 458}} bis_skin_checked="1"
            >
              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="required fw-semibold fs-6 mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  value={code.name}
                  onChange={e => this._handleChange(e)}
                />
                <span class="text-muted">Displayed To User</span>
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="required fw-semibold fs-6 mb-2">Value</label>
                <input
                  type="text"
                  name="value"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder="Value"
                  value={code.value}
                  onChange={e => this._handleChange(e)}
                />
                <span class="text-muted">Sent To Revenue</span>
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="required fw-semibold fs-6 mb-2">Description</label>
                <input
                  type="text"
                  name="description"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder="Description"
                  value={code.description}
                  onChange={e => this._handleChange(e)}
                />
                <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-right">
              <button
                className="btn btn-light ml-auto"
                onClick={() => this.props.onHide()}
              >
                Close
              </button>
              <button
                className="btn btn-primary ms-3"
                disabled={loading}
                onClick={e => {
                  this._save()
                }}
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

}
