import React from "react"

import { Link, withRouter } from "react-router-dom";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import BaseTable from "./BaseTable"
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import Account from "../modal/Account";
import moment from "moment";
import UsersTable from "./UsersTable";
import Confirmation from "../modal/Confirmation";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

class AccountsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: "Account",
        id: "name",
        accessor: account => (
          <>
            <div className="d-flex align-items-center" bis_skin_checked="1">
              <div className="d-flex flex-column justify-content-center" bis_skin_checked="1">
                <a href="" className="mb-1 text-gray-800 text-hover-primary">
                  {account.name}
                </a>
              </div>
            </div>
          </>
        )
      },
      {
        Header: "Declarations",
        id: "declarations_created_no",
        accessor: "declarations_created_no"
      },
      {
        Header: "Date Added",
        id: 'created_at',
        accessor: account => moment(account.created_at).format('MMM, DD YYYY'),
      },
      {
        Header: "Actions",
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let account = rowInfo.original
          return (
            <>
              {/*<Edit*/}
              {/*  onEditClicked={() => this.setState({showUsersModal: true, selectedAccount: account})}*/}
              {/*/>*/}
              <Delete
                onDeleteClicked={() => this.setState({selectedAccount: account, showConfirmationModal: true})}
              />
            </>
          )
        }
      },
    ]

    return columns
  }

  _handleDelete(){
    Backend.deleteAccount(this.state.selectedAccount).then(() => {
      Notify.success("Account Deleted")
      this.setState({selectedAccount: null, showConfirmationModal: false})
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _renderMembers(company){
    return (
      <UsersTable
        title={"Company Members"}
        company={company}
      />
    )
  }

  render() {
    let {
      showUsersModal,
      selectedAccount,
      showConfirmationModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Companies}
          noDataMessage={"No accounts found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  href="#"
                  className="btn btn-light-primary btn-active-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                  onClick={() => this.setState({showUsersModal: true})}
                >
                  <span className="svg-icon svg-icon-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                            transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                      <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                    </svg>
                  </span>
                  Add Account
                </a>
              </div>
            </>
          )}
          SubComponent={(row) => this._renderMembers(row.original)}
        />
        {
          showUsersModal &&
          <Account
            show={showUsersModal}
            account={selectedAccount}
            onHide={() => this.setState({showUsersModal: false, selectedAccount: null})}
            onSubmit={() => {
              this.table.current.refresh()
              this.setState({showUsersModal: false, selectedAccount: null})
            }}
          />
        }
        {
          showConfirmationModal &&
          <Confirmation
            show={showConfirmationModal}
            onHide={() => this.setState({showConfirmationModal: false, selectedAccount: null})}
            onConfirm={() => this._handleDelete()}
          />
        }
      </>
    )

  }

}

export default withRouter(AccountsTable);
