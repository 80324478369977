import React from "react"

import DeclarationsTable from "./components/tables/SubmissionsTable";
import AuthManager from "../utils/AuthManager";

export default class Submissions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {

  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <DeclarationsTable
              title={"Declarations"}
              endpoint={`${window.Api.Submissions}?draft=false`}
              showDateRange={false}
            />
          </div>
        </div>
      </>
    )
  }

}