import React from "react"

import MainSection from "./components/form/MainSection";

import General from "../utils/General";
import Notify from "../utils/Notify";
import Backend from "../utils/Backend";
import AuthManager from "../utils/AuthManager";

export default class DeclarationForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      previousIndex: null,
      currentIndex: 0,
      answers: [],
      editable: false
    }

    this.mainSectionRefs = {}
  }

  componentDidMount() {
    let submissionId =  this.props.inModal ? this.props.submissionId : this.props.match.params.submissionId

    if(submissionId){
      Backend.getSubmission(submissionId).then(submission => {
        let form = submission.form
        this.setState({submission, form})
      }).catch(e => Notify.error(e.message))
    }
  }

  _autoSave = General.debounce(() => {
    let { submission } = this.state
    let amendment = null
    if(submission.mrn){
      amendment = {status: "draft"}
    }
    this._handleSubmission(null, false, amendment)
  }, 2000)

  // todo: review / fix check and auto save
  _handleNextButton(){
    this.setState({currentIndex: this.state.currentIndex + 1})
  }

  _getSectionAnswers(section){
    let answers = []
    section.rows.forEach(row => {
      row.fields.forEach(field => {
        if(!field.answer || field.format.type === "label") {
          return
        }
        let answer = General.clone(field.answer)

        if(field.format.type === "party"){
          answer.party = answer.party?.id
        }else if(field.format.type === "guarantee"){
          answer.guarantee = answer.guarantee?.id
        }else if(field.format.type === "shipment_item"){
          answer.shipment_item = answer.shipment_item?.id
        }

        answer.field = field.id
        answers.push(answer)
      })
    })

    section.sections.forEach(subSection => {
      answers = [
          ...answers,
          ...this._getSectionAnswers(subSection)
      ]
    })

    return answers
  }

  _getAnswers(){
    let {
      form,
    } = this.state

    let answers = []

    form.sections.forEach(section => {
      answers = [
          ...answers,
          ...this._getSectionAnswers(section)
      ]
    })

    return answers
  }

  _handleSubmission(status, showToast=true, amendment=null){
    let { submission } = this.state

    let data = {
      answers: this._getAnswers(),
    }

    if(submission.template){
      data.name = submission.name
    }
    if(status){
      data.status = status
    }
    if(amendment){
      data.amendment_status = amendment.status
      if(amendment.reason){
        data.amendment_reason = amendment.reason
      }
    }

    this.setState({isSubmitting: true})

    Backend.updateDeclaration(submission.id, data)
      .then(submission => {
        if(status === "completed"){
          if(!AuthManager.currentUser.company.onboarding_checklist.submission){
            AuthManager.currentUser.company.onboarding_checklist.submission = true
          }
        }

        this.setState({
          // submission,
          // form: submission.form,
          isSubmitting: false,
        })

        let message = "Declaration Saved"
        if(submission.status === "processing"){
          message = "Declaration Submitted"
        }else if(submission.template){
          message = "Template Saved"
        }

        if(showToast){
          Notify.success(message)
        }

        if(submission.status === "processing" || submission.amendment_status == "processing"){
          this.props.history.push("/")
        }
      })
      .catch(e => {
        this.setState({isSubmitting: false})
        if(showToast) {
          Notify.error(e.message)
        }
      })

  }

  _updateSection(section, index){
    let {
      form
    } = this.state

    form.sections[index] = section
    this.setState({ form })
  }

  _renderMainSections(){
    let {
      form,
      submission,
      currentIndex,
    } = this.state

    return form.sections.map((section, index) => {
      return (
        <MainSection
            ref={ref => this.mainSectionRefs[index] = ref}
            editable={this.state.editable}
            section={section}
            index={index}
            currentIndex={currentIndex}
            submission={submission}
            onUpdated={sectionData => {
              this._updateSection(sectionData, index)
            }}
            onBlur={() => this._autoSave()}
        />
      )
    })
  }

  _renderSteps(form){
    let {
      submission,
      currentIndex
    } = this.state

    return (
        <div className="d-flex flex-row-auto w-100 w-lg-300px">
          <div className="stepper-nav flex-cente">
            {
              form.sections.map((section, index) => {
                let className = "current"
                if(index > currentIndex){
                  className = "pending"
                }else if(index < currentIndex){
                  className = "completed"
                }
                return (
                  <>
                    <div
                      className={`stepper-item me-5 ${className}`}
                      data-kt-stepper-element="nav"
                      data-kt-stepper-action="step"
                      onClick={e => {
                        if(index < currentIndex) {
                          this.setState({currentIndex: index})
                          return
                        }
                        this._handleNextButton(currentIndex)
                      }}
                    >
                      <div className="stepper-wrapper d-flex align-items-center">
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">{index+1}</span>
                        </div>
                        <div className="stepper-label">
                          <h3 className="stepper-title">
                            {section.title}
                          </h3>
                        </div>
                      </div>
                      <div className="stepper-line h-40px"></div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
    )
  }

  render() {
    let {
      form,
      submission,
      isSubmitting,
      currentIndex,
      showAmendModal,
      showConfirmationModal
    } = this.state

    if(!submission) return null

    let title = (
      <span className="fs-4 text-gray-700 fw-bold pe-3 d-none d-md-block">
        {`${submission.form.name} Declaration`}
      </span>
    )

    if(submission.template){
      title = (
        <div className="row">
          <div className="col-8">
          <input
            value={submission.name}
            onChange={e => {
              submission.name = e.target.value || null
              this.setState({submission})
            }}
            onBlur={() => this._autoSave()}
            className="form-control form-control-solid"
          />
          </div>
          <div className="col align-self-center">
            <label>Template</label>
          </div>
        </div>
      )
    }

    return (
      <>
        {
          submission.latest_state?.reason == "Declaration Rejected" &&
          <div className={"bg bg-light-danger p-8 mb-4"}>
            <div style={{whiteSpace: "pre-wrap"}}>{submission.latest_state.message}</div>
          </div>
        }
        <div className="toolbar" id="kt_toolbar">
          <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack"
               bis_skin_checked="1">
            <div className="d-flex align-items-center me-5 py-4" bis_skin_checked="1">
              <div className="d-flex align-items-center flex-shrink-0" bis_skin_checked="1">
                {title}
                <div className="d-flex flex-shrink-0" bis_skin_checked="1">
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center" bis_skin_checked="1">

            </div>
          </div>
        </div>
        <div className="card mt-30 mb-xl-8">
          <div className="card-body py-10">
            <div className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row first">
              {this._renderSteps(form)}
              <div className="flex-row-fluid">
                <form className="form mx-auto">
                  <div className="mb-5">
                    {this._renderMainSections()}
                  </div>
                  <div className="d-flex flex-stack">
                    <div className="me-2">
                      {
                        currentIndex > 0 &&
                        <button
                          type="button"
                          className="btn btn-light btn-active-light-primary"
                          onClick={() => this.setState({currentIndex: currentIndex-1})}
                        >
                          Back
                        </button>
                      }
                    </div>

                    <div>
                      {
                        currentIndex < form.sections.length - 1 &&
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => this._handleNextButton(currentIndex)}
                        >
                          Continue
                        </button>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
