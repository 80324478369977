import React from "react";

import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";

export default class Codes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      listKey: props.listKey,
      placeholder: props.placeholder,
      required: props.required,
      editable: props.editable,
      queryParams: props.queryParams,
      isMulti: props.isMulti
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      value,
      listKey,
      placeholder,
      required,
      editable,
      queryParams,
      isMulti
    } = this.state

    let filter = `list_key=${listKey}`
    for (const [key, value] of Object.entries(queryParams || {})) {
      filter += `&${key}=${value}`
    }

    let values = isMulti ? General.getAsyncOptions(value, this.props.nameKey) : General.getSingleAsyncOption(value, "value")
    return (
        <div className="mb-5">
          <label className={"mb-2"}>{placeholder} {required ? "*" : ""}</label>
          <AsyncSelect
            placeholder={placeholder}
            endpoint={window.Api.Codes}
            isClearable={true}
            disabled={!editable}
            orderBy={"name"}
            filter={filter}
            search={queryParams?.search_term || null}
            className={'custom-async-select__container'}
            classNamePrefix={'custom-async-select'}
            isMulti={isMulti}
            value={values}
            getOptions={options => {
              return General.getAsyncOptions(options, this.props.nameKey)
            }}
            onSelected={option => {
              let values = option
              if(isMulti && values){
                values = values.map(value => value.data)
              }
              this.props.onSelected(values || null)
            }}
          />
        </div>
    )
  }

}

Codes.defaultProps = {
  required: false,
  nameKey: "name",
  queryParams: {},
  isMulti: false
}
