import React from "react"

import Row from "./Row";
import Section from "./Section";

import General from "../../../utils/General";
import Form from "../../../utils/Form";


export default class MainSection extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      editable: props.editable,
      section: props.section,
      index: props.index,
      currentIndex: props.currentIndex,
      submission: props.submission,
      subSectionsTitle: Form.getSubSectionsTitle(props.section),
      subSectionsCreatable: Form.getSubSectionsCreatable(props.section),
      subSectionCreatableTemplate: Form.getSubSectionCreatableTemplate(props.section)
    }

    this.rowRefs = {}
    this.subSectionRefs = {}
  }

  componentWillReceiveProps(props) {
    this.setState(props)
  }

  getErrors(){
    let errors = []
    Object.keys(this.rowRefs).map((key, index) => {
      let rowRef = this.rowRefs[index]
      errors = [
          ...errors,
          ...rowRef.getErrors()
      ]
    })
    Object.keys(this.subSectionRefs).map((key, index) => {
      let subSectionRef = this.subSectionRefs[index]
      errors = [
          ...errors,
          ...subSectionRef.getErrors()
      ]
    })
    return errors
  }

  _renderRows(){
    let {
      editable,
      section,
      submission
    } = this.state

    return section.rows.map((row, rowIndex) => {
      return (
        <Row
          ref={ref => this.rowRefs[rowIndex] = ref}
          editable={editable}
          row={row}
          index={rowIndex}
          group={0}
          submission={submission}
          onUpdated={rowData => {
            let sectionData = General.clone(section)
            sectionData.rows[rowIndex] = rowData
            this.props.onUpdated(sectionData)
          }}
          onBlur={this.props.onBlur}
        />
      )
    })
  }

  render(){
    let {
      index,
      section,
      editable,
      submission,
      currentIndex,
      subSectionsTitle,
      subSectionsCreatable,
      subSectionCreatableTemplate
    } = this.state

    let className = "current"
    if(index > currentIndex){
      className = "pending"
    }else if(index < currentIndex){
      className = "completed"
    }

    if(submission.template && section.sections.length > 0 && subSectionsTitle === "Product"){
      // allow only specifying one product in a template
      subSectionsCreatable = false
    }

    return (
        <>
          <div className={`flex-column ${className}`} data-kt-stepper-element="content">
            <h3 className="fw-bold mb-5">{section.title}</h3>
            {
                section.sections.length > 0 &&
                <div className="accordion">
                  {
                    section.sections.map((subSection, subSectionIndex) => {
                      return (
                          <Section
                              ref={ref => this.subSectionRefs[subSectionIndex] = ref}
                              section={subSection}
                              editable={editable}
                              index={subSectionIndex}
                              submission={submission}
                              level={0}
                              group={subSectionsCreatable ? subSectionIndex : 0}
                              onUpdated={subSectionData => {
                                let sectionData = General.clone(section)
                                sectionData.sections[subSectionIndex] = subSectionData
                                this.props.onUpdated(sectionData)
                              }}
                              onDelete={() => {
                                let sectionData = General.clone(section)
                                sectionData.sections.splice(subSectionIndex, 1)
                                this.props.onUpdated(sectionData)
                              }}
                              onBlur={this.props.onBlur}
                          />
                      )
                    })
                  }
                </div>
            }

            {
                (subSectionsCreatable && editable) && (
                    <div className="row mt-5">
                      <div className="col-md-12 mb-10">
                        <a className="btn btn-light-primary"
                           onClick={() => {
                             let sectionData = General.clone(section)
                             let group = sectionData.sections.length
                             let blankSubSection = Form.resetAnswers(subSectionCreatableTemplate, group)
                             sectionData.sections.push(blankSubSection)
                             this.props.onUpdated(sectionData)
                           }}
                        >
                          <i className="la la-plus"></i>
                          Add {subSectionsTitle}
                        </a>
                      </div>
                    </div>
                )
            }

            { this._renderRows() }
          </div>
        </>
    )
  }
}
