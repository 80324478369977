import React from "react"

import AdminsTable from "./components/tables/AdminsTable";

export default class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <AdminsTable
              title={"Users"}
            />
          </div>
        </div>
      </>
    )
  }

}