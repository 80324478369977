import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

export default class VatSummary extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  render() {

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a
          className="btn btn-icon btn-sm btn-secondary me-2"
          onClick={() => this.props.onVatSummaryClicked()}
        >
          <span
            className="svg-icon svg-icon-1"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path opacity="0.3" d="M20 18H4C3.4 18 3 17.6 3 17V7C3 6.4 3.4 6 4 6H20C20.6 6 21 6.4 21 7V17C21 17.6 20.6 18 20 18ZM12 8C10.3 8 9 9.8 9 12C9 14.2 10.3 16 12 16C13.7 16 15 14.2 15 12C15 9.8 13.7 8 12 8Z" fill="currentColor"/>
              <path d="M18 6H20C20.6 6 21 6.4 21 7V9C19.3 9 18 7.7 18 6ZM6 6H4C3.4 6 3 6.4 3 7V9C4.7 9 6 7.7 6 6ZM21 17V15C19.3 15 18 16.3 18 18H20C20.6 18 21 17.6 21 17ZM3 15V17C3 17.6 3.4 18 4 18H6C6 16.3 4.7 15 3 15Z" fill="currentColor"/>
            </svg>
          </span>
        </a>
      </Tooltip>
    )
  }
}

VatSummary.defaultProps = {
  tooltip: "VAT Summary",
}
