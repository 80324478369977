import React from "react";
import Modal from 'react-bootstrap/Modal'

import ItemForm from "../item/Form";

import Codes from "../common/Codes"

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Form from "../../../utils/Form";

export default class Item extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      ...this._getState(props)
    }
  }

  componentDidMount() {
    this._getProduct()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      submission: props.submission,
      productId: props.productId
    }
  }

  _getProduct(){
    let {
      productId
    } = this.state

    if(!productId){
      this.setState({ product: this.state.product || Form.getItemTemplate()})
      return
    }

    Backend.getProduct(productId)
    .then(product => {
      this.setState({product})
    })
    .catch(e => Notify.error(e.message))

  }

  _isFormValid(){
    let {
      product
    } = this.state

    let error = null

    // if(!product.name){
    //   error = "Please enter a name"
    // }else if(!product.description){
    //   error = "Please enter a description"
    // }else if(!product.quantity){
    //   error = "Please enter the quantify"
    // }else if(!product.commodity_code.taric){
    //   error = "Please select a TARIC code"
    // }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _createProduct(){
    let {
      product,
    } = this.state

    if (!this._isFormValid()) {
      return
    }

    this.setState({loading: true})

    this._handleBackend(product)
    .then(product => {
      Notify.success("Product Saved")
      this.setState({loading: false})
      this.props.onSubmit(product)
    })
    .catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _handleBackend(product){
    if(product.id){
      return Backend.updateProduct(product)
    }
    else{
      return Backend.addProduct(product)
    }
  }

  _updateAuthManager(){
    if(!AuthManager.currentUser.company.onboarding_checklist.item){
      AuthManager.currentUser.company.onboarding_checklist.item = true
    }
  }

  render() {
    let {
      show,
      product,
      loading,
    } = this.state

    let buttonTitle = this.props.editable ? 'Save Product' : 'Close'

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Product</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            { product &&
              <ItemForm
                editable={this.props.editable}
                product={product}
                onUpdated={product => {
                  this.setState({ product })
                }}
              />
            }

            <div className="form-group text-end mt-10">
              <button
                type="button"
                className="btn btn-light-primary"
                disabled={loading}
                onClick={() => {
                  if(this.props.editable) {
                    this._createProduct()
                  }else {
                    this.props.onHide()
                  }
                }}
              >
                {buttonTitle}
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }


}

Item.defaultProps = {
  declaration: true,
  editable: true
}
