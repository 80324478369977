import React from "react"

import { Link, withRouter } from "react-router-dom";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import BaseTable from "./BaseTable"
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import CompanyMember from "../modal/CompanyMember";
import moment from "moment";

class UsersTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live,
      company: props.company,
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: "User",
        id: "company_member.user.first_name",
        accessor: company_member => (
          <>
            <div className="d-flex align-items-center" bis_skin_checked="1">
              <div className="d-flex flex-column justify-content-center" bis_skin_checked="1">
                <a href="" className="mb-1 text-gray-800 text-hover-primary">
                  {company_member.user.first_name} {company_member.user.last_name}
                </a>
                <div className="fw-semibold fs-6 text-gray-400" bis_skin_checked="1">
                  {company_member.user.email}
                </div>
              </div>
            </div>
          </>
        )
      },
      {
        Header: "Date Added",
        id: 'created_at',
        accessor: company_member => moment(company_member.created_at).format('MMM, DD YYYY'),
      },
      {
        Header: "Actions",
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let companyMember = rowInfo.original
          return (
            <>
              <Edit
                onEditClicked={() => this.setState({showUsersModal: true, selectedCompanyMember: companyMember})}
              />
              <Delete/>
            </>
          )
        }
      },
    ]

    return columns
  }

  render() {
    let {
      company,
      showUsersModal,
      selectedCompanyMember
    } = this.state

    const columns = this._getColumns()

    let endpoint = company ? `${window.Api.CompanyMembers}?company_id=${company.id}` : window.Api.Admins

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={"No users found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  href="#"
                  className="btn btn-light-primary btn-active-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                  onClick={() => this.setState({showUsersModal: true})}
                >
                  <span className="svg-icon svg-icon-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                            transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                      <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                    </svg>
                  </span>
                  Add User
                </a>
              </div>
            </>
          )}
        />
        {
          showUsersModal &&
          <CompanyMember
            show={showUsersModal}
            company={company}
            companyMember={selectedCompanyMember}
            onHide={() => this.setState({showUsersModal: false, selectedCompanyMember: null})}
            onSubmit={() => {
              this.table.current.refresh()
              this.setState({showUsersModal: false, selectedCompanyMember: null})
            }}
          />
        }
      </>
    )

  }

}

export default withRouter(UsersTable);
