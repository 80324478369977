import React from "react";
export default class Input extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            type: props.type,
            editable: props.editable,
            placeholder: props.placeholder,
            required: props.required,
            value: props.value
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    render(){
        let {
            type,
            editable,
            placeholder,
            required,
            value
        } = this.state

        return (
            <div className="mb-5">
                <label className={"mb-2"}>{placeholder} {required ? "*" : ""}</label>
                <input
                    type={type}
                    disabled={!editable}
                    style={{ height:  47.8 }}
                    className="form-control"
                    placeholder={placeholder}
                    value={value}
                    onChange={e => {
                        this.props.onChange(e.target.value || null)
                    }}
                />
            </div>
        )
    }
}

Input.defaultProps = {
    declaration: true
}
