import React from "react";

import CodeInput from "./CodeInput"

import Input from "./Input";
import SmartList from "../common/SmartList";

export default class AdditionalInformation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            additionalInformation: props.additionalInformation
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderAdditionalInformation(info, index){
        let {
            editable,
            additionalInformation
        } = this.state
        return (
            <div className="form-group row mb-5">
                <div className="col-md-6">
                    <CodeInput
                        placeholder={"Code"}
                        listKey={"AdditionalInformationCode"}
                        value={info.code}
                        editable={editable}
                        onSelected={option => {
                            additionalInformation[index].code = option
                            this.props.onUpdated(additionalInformation)
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <Input
                        type="text"
                        placeholder="Text"
                        value={info.text}
                        editable={editable}
                        onChange={text => {
                            additionalInformation[index].text = text
                            this.props.onUpdated(additionalInformation)
                        }}
                    />
                </div>
            </div>
        )
    }


    render() {
        let {
            editable,
            additionalInformation,
        } = this.state

        let itemName = "Additional Information"
        return (
            <SmartList
                items={additionalInformation}
                itemName={itemName}
                showItemName={true}
                canDelete={editable}
                canAdd={editable}
                renderItem={(item, index) => this._renderAdditionalInformation(item, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            additionalInformation.splice(index, 1)
                            this.props.onUpdated(additionalInformation)
                        }}
                    >
                        <i className="la la-trash-o fs-3"></i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    additionalInformation = items
                    this.props.onUpdated(additionalInformation)
                }}
                onAddItemPressed={() => {
                    additionalInformation.push({})
                    this.props.onUpdated(additionalInformation)
                }}
            />
        )
    }


}

AdditionalInformation.defaultProps = {

}
