import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class FieldHelpText extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      field: props.field,
      data: {
        id: props.field.id,
        box_code: props.field.box_code,
        help_text: props.field.help_text,
        recommended: props.field.recommended
      }
    }
  }

  _handleChange(e) {
    let { data } = this.state
    data[e.target.name] = e.target.value || null
    this.setState({ data, error: null })
  }

  _save(){
    let { data, field } = this.state

    this.setState({loading: true})
    Backend.updateField(data)
    .then(() => {
      field.box_code = data.box_code
      field.help_text = data.help_text
      field.recommended = data.recommended

      this.setState({loading: false})
      Notify.success("Field Saved!")
      this.props.onUpdated()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      data,
      field,
      loading,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit {field.title} Help Text</Modal.Title>
          </Modal.Header>

          <Modal.Body>

              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="fw-semibold fs-6 mb-2">Box Code / Data Element</label>
                <input
                  type="text"
                  name="box_code"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder={"Box Code / Data Element"}
                  value={data.box_code}
                  onChange={e => this._handleChange(e)}
                />
              </div>

              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="fw-semibold fs-6 mb-2">Recommended</label>
                <select
                    className="form-select"
                    aria-label="Container"
                    onChange={e => {
                      data.recommended = e.target.value == "true"
                    }}
                >
                  <option value="true" selected={data.recommended == true}>True</option>
                  <option value="false" selected={data.recommended == false}>False</option>
                </select>
                <span class="text-muted">Whether or not user's are advised before submitting this declaration to fill in this field</span>
              </div>

              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="required fw-semibold fs-6 mb-2">Help Text</label>
                <textarea
                  type="text"
                  name="help_text"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder={field.placeholder}
                  minRows="1"
                  value={data.help_text}
                  onChange={e => this._handleChange(e)}
                />
                <span class="text-muted">Help Text Displayed To User</span>
              </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="text-right">
              <button
                className="btn btn-light ml-auto"
                onClick={() => this.props.onHide()}
              >
                Close
              </button>
              <button
                className="btn btn-primary ms-3"
                disabled={loading}
                onClick={e => {
                  this._save()
                }}
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

}
