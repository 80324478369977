import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import Party from "../modal/Party";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Confirmation from "../modal/Confirmation";

class ListsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.table = React.createRef()
  }


  _getColumns(){
    let columns = [
      {
        Header: 'Name',
        id: 'name',
        accessor: list => General.toTitleCase(list.name),
      },
      {
        Header: '',
        id: '',
        width: 100,
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let list = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <Edit
                onEditClicked={() => {
                  this.props.history.push(`/code-list/${list.id}`)
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Lists}`}
          noDataMessage={"No lists found"}
          title={this.props.title}
          columns={columns}
          defaultSorted={[
            {
              id: "name",
              desc: false
            }
          ]}
        />

      </>
    )

  }

}

export default withRouter(ListsTable);
