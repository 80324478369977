import React from "react"

import General from "../../../utils/General";

export default class Label extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            submission: props.submission
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }
    render(){
        let {
            field,
            submission
        } = this.state

        let value = field.answer?.value

        if(field.placeholder === "LRN"){
            value = submission?.lrn
        }else if(field.placeholder === "Status"){
            value = General.toTitleCase(submission?.status)
        }else if(field.placeholder === "Declarant"){
            value = submission?.declarant?.name
        }

        return (
            <input
                type="text"
                className="form-control"
                placeholder={field.placeholder}
                value={value}
                disabled={true}
            />
        )
    }
}