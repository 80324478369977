import React from "react";

import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";

export default class Codes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      filter: props.filter,
      orderBy: props.orderBy,
      placeholder: props.placeholder,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      value,
      filter,
      orderBy,
      placeholder
    } = this.state

    return (
      <AsyncSelect
        placeholder={placeholder}
        endpoint={window.Api.Codes}
        orderBy={orderBy}
        filter={filter}
        className={'custom-async-select__container'}
        classNamePrefix={'custom-async-select'}
        value={General.getSingleAsyncOption(value, "value")}
        getOptions={options => {
          return General.getAsyncOptions(options)
        }}
        onSelected={option => this.props.onSelected(option)}
        onBlur={() => this.props.onBlur()}
      />
    )
  }

}

Codes.defaultProps = {
  onBlur: () => null
}