import React from "react"

import ShipmentItemModal from "../../components/modal/ShipmentItem";

import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";

export default class ShipmentItem extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            editable: props.editable,
            submission: props.submission
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError() {
        let {
            field
        } = this.state

        if (field.is_required && !field.answer?.shipment_item) {
            return "Please select an item"
        }
        return null
    }

    render(){
        let {
            field,
            editable,
            submission,
            showItemModal,
            selectedShipmentItem,
            existingShipmentItem
        } = this.state

        let value = null
        let shipmentItem = field.answer?.shipment_item

        let buttonTitle = editable ? 'Edit' : 'View'

        if(shipmentItem){
            value = {
                value: shipmentItem.id,
                label: shipmentItem.name,
            }
            if(shipmentItem.commodity_code.taric){
              value.label += ` ${shipmentItem.commodity_code.taric.value}`
            }
        }

        return (
            <div className={"row"}>
                <div className={"col-10"}>
                    <AsyncSelect
                        placeholder={field.placeholder}
                        endpoint={window.Api.Items}
                        isClearable={true}
                        disabled={!editable}
                        creatable={true}
                        orderBy={"name"}
                        className={'custom-async-select__container'}
                        classNamePrefix={'custom-async-select'}
                        value={value}
                        getOptions={options => {
                            return options.map(option => {
                                return {
                                    value: option.id,
                                    label: `${option.name} - ${option.commodity_code.taric.value}`,
                                    data: option
                                }
                            })
                        }}
                        onSelected={option => {
                            this.setState({
                                selectedShipmentItem: option,
                                showItemModal: true,
                                existingShipmentItem: false
                            })
                        }}
                        onBlur={() => {

                        }}
                        onCreated={option => {
                            let selectedShipmentItem = getTemplateItem(option.value)
                            this.setState({
                                selectedShipmentItem,
                                showItemModal: true,
                                existingShipmentItem: false
                            })
                        }}
                    />
                </div>

                <div className={"col-2 text-end"}>
                  { shipmentItem &&
                    <a className={"btn btn-light-info w-100"}
                        onClick={() => {
                            this.setState({
                                selectedShipmentItem: shipmentItem,
                                showItemModal: true,
                                existingShipmentItem: true
                            })
                        }}
                    >
                        {buttonTitle}
                    </a>
                  }
                </div>

                { showItemModal &&
                    <ShipmentItemModal
                        show={showItemModal}
                        editable={editable}
                        productId={selectedShipmentItem?.id}
                        submission={submission}
                        existingShipmentItem={existingShipmentItem}
                        onHide={e => this.setState({shipmentItem: null, showItemModal: false, existingShipmentItem: false})}
                        onSubmit={shipmentItem => {
                            let fieldData = General.clone(field)
                            if(shipmentItem){
                                fieldData.answer = {
                                    id: field.answer?.id,
                                    shipment_item: shipmentItem
                                }
                            }else{
                                delete fieldData.answer
                            }
                            this.props.onUpdated(fieldData)

                            this.setState({shipmentItem: null, showItemModal: false, existingShipmentItem: false})
                        }}
                    />
                }
            </div>
        )
    }
}

function getTemplateItem(name){
    return {
        name,
        quantity: 1,
        additional_procedures: [],
        additional_information: [],
        taxes: [],
        documents: [],
        packages: [],
        valuation: {
            operations: []
        },
        commodity_code: {
            national_additional_codes: [],
            taric_additional_codes: [],
        },
        dates_and_places: {},
        transport_documents: [],
    }
}
