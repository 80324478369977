import React from "react";

import CodeInput from "./CodeInput"

import Codes from "../common/Codes"
import Input from "./Input";
import SmartList from "../common/SmartList";

export default class Documents extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            documents: props.documents,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderDocuments(document, index){
        let {
            editable,
            documents
        } = this.state
        return (
            <div className="form-group row mb-5">
                <div className="col-md-6">
                    <CodeInput
                        placeholder={"Document Type"}
                        listKey={"CommonDocumentsType"}
                        value={document.type}
                        editable={editable}
                        onSelected={option => {
                            documents[index].type = option
                            this.props.onUpdated(documents)
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <Input
                        type="text"
                        placeholder="Document Identifier"
                        value={document.identifier}
                        editable={editable}
                        onChange={identifier => {
                            documents[index].identifier = identifier
                            this.props.onUpdated(documents)
                        }}
                    />
                </div>
            </div>
        )
    }


    render() {
        let {
            editable,
            documents,
        } = this.state

        let itemName = "Document"
        return (
            <SmartList
                items={documents}
                itemName={itemName}
                showItemName={true}
                canDelete={editable}
                canAdd={editable}
                renderItem={(document, index) => this._renderDocuments(document, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            documents.splice(index, 1)
                            this.props.onUpdated(documents)
                        }}
                    >
                        <i className="la la-trash-o fs-3"></i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    documents = items
                    this.props.onUpdated(documents)
                }}
                onAddItemPressed={() => {
                    documents.push({})
                    this.props.onUpdated(documents)
                }}
            />
        )
    }


}

Documents.defaultProps = {

}
