import React from "react";
import Modal from 'react-bootstrap/Modal'
import PhoneInput from "react-phone-input-2";

import CodeInput from "../item/CodeInput";
import LocationInput from "../common/LocationInput";
import AsyncSelect from "../common/AsyncSelect";

import CustomDropzone from "../common/CustomDropzone";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";

const parties = ["buyer", "owner", "client", "seller", "contact", "importer", "exporter", "declarant", "representative"]

export default class Party extends React.Component {

  constructor(props) {
    super(props);

    let forcedPartyType = props.forcedPartyType
    this.state = {
      show: props.show,
      party: props.party || {
        type: forcedPartyType || null
      },
      forcedPartyType: forcedPartyType
    }
  }

  componentDidMount() {

  }

  _handleSubmission(){
    let {
      party
    } = this.state

    if(party.id){
      this._updateParty(party)
    }else{
      this._addParty(party)
    }
  }

  _addParty(party){
    this.setState({loading: true})
    Backend.addParty(party).then(party => {
      if(party.type === "client"){
        if(!AuthManager.currentUser.company.onboarding_checklist.client){
          AuthManager.currentUser.company.onboarding_checklist.client = true
        }
      }
      this.setState({loading: false})
      Notify.success("Party Added")
      this.props.onSubmit(party)
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _updateParty(party){
    if(party.type == "declarant" && party.certificate){
      if(party.certificate.file && !party.certificate.password){
        Notify.error("Please add the certificate password")
        return
      }
      if(party.certificate.password && !party.certificate.file){
        Notify.error("Please add the ROS Certificate")
        return
      }
    }

    this.setState({loading: true})
    Backend.updateParty(party)
    .then(party => {
      this.setState({loading: false})
      Notify.success("Party Updated")
      this.props.onSubmit(party)
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      party,
      loading,
      forcedPartyType
    } = this.state

    let title = party.id ? "Update Party" : `Add ${this.props.name}`
    let phoneNumber = party.phone_country_code ? `${party.phone_country_code}${party.phone_number}` : '+353'

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-40"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex-column" data-kt-stepper-element="content">
              <div className="row">
                {
                  (party.type !== "authorisation_holder" && party.type !== "client" && party.type !== "contact") &&
                  <div className="col-md-12">
                    <div className="fv-row mb-3">
                      <div className="form-floating mb-2">
                        <select
                          className="form-select"
                          id="partyType"
                          aria-label="Party Type"
                          onChange={e => {
                            party.type = e.target.value
                            this.setState({party})
                          }}
                          disabled={party.id || forcedPartyType}
                        >
                          <option>Party Type</option>
                          {
                            parties.map(partyOption => (
                              <option value={partyOption} selected={party.type === partyOption}>{General.toTitleCase(partyOption)}</option>
                            ))
                          }
                        </select>
                        <div className="position-absolute translate-middle-y top-50 end-0 me-10">
                          <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                             data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                        </div>
                        <label htmlFor="partyType">Party Type</label>
                      </div>
                    </div>
                  </div>
                }
                {
                  party.type !== "owner" && party.type !== "contact" &&
                  <div className="col">
                    <div className="fv-row mb-3">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="identificationNumberRepresentative"
                          placeholder={party.type == "client" ? "Identifier" : "EORI Number"}
                          value={party.identification_number}
                          onChange={e => {
                            party.identification_number = e.target.value || null
                            this.setState({party})
                          }}
                        />
                        <label htmlFor="identificationNumberRepresentative">{party.type == "client" ? "Identifier" : "EORI Number"}</label>
                      </div>
                    </div>
                  </div>
                }
                {
                  party.type === "representative" &&
                  <div className="col-md-6">
                    <div className="fv-row mb-3">
                      <div className="form-floating mb-2">
                        <select
                          className="form-select"
                          id="identificationNumberRepresentative"
                          aria-label="Representative Status Code"
                          onChange={e => {
                            party.indirect_representative = e.target.value === "indirect"
                            this.setState({party})
                          }}
                        >
                          <option>Select</option>
                          <option value="direct" selected={!party.indirect_representative}>Direct representative</option>
                          <option value="indirect" selected={party.indirect_representative}>Indirect representative</option>
                        </select>
                        <label htmlFor="identificationNumberRepresentative">Representative Status Code</label>
                      </div>
                    </div>
                  </div>
                }
                <div className="col-md-12">
                  <div className="fv-row mb-3">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="declarantName"
                        placeholder="Add Identification Name"
                        value={party.name}
                        onChange={e => {
                          party.name = e.target.value || null
                          this.setState({party})
                        }}
                      />
                      <label htmlFor="declarantName">Name</label>
                    </div>
                  </div>
                </div>

                {
                  party.type === "contact" &&
                  <>
                    <div className="col-md-6">
                      <div className="fv-row mb-3">
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="declarantName"
                            placeholder="Email"
                            value={party.email}
                            onChange={e => {
                              party.email = e.target.value || null
                              this.setState({party})
                            }}
                          />
                          <label htmlFor="declarantName">Email</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="fv-row mb-3">
                        <div className="form-floating mb-2">
                          <PhoneInput
                            value={phoneNumber}
                            onChange={(value, data) => {
                              party.phone_country_code = `+${data.dialCode}`
                              party.phone_number = value.replace(/[^0-9]+/g, '').slice(data.dialCode.length)
                              this.setState({party})
                            }}
                            inputProps={{
                              autocomplete: 'off',
                              className: "form-control "
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }

                {
                  party.type === "authorisation_holder" &&
                  <div className="col">
                    <div className="fv-rom mb-3">
                      <div className="form-floating mb-2">
                        <AsyncSelect
                          placeholder="Codes"
                          endpoint={window.Api.Codes}
                          orderBy={"name"}
                          filter={"list_key=AuthorisationTypeCode"}
                          className={'custom-async-select__container'}
                          classNamePrefix={'custom-async-select'}
                          value={General.getSingleAsyncOption(party.authorisation, "value")}
                          getOptions={options => General.getAsyncOptions(options)}
                          onSelected={option => {
                            party.authorisation = option
                            this.setState({party})
                          }}
                        />
                      </div>
                    </div>
                  </div>
                }

                <div className="col-md-12">
                  <div className="fv-row mb-3">
                    <div className="form-floating mb-2">
                      <LocationInput
                        className="form-control"
                        placeholder={"Address"}
                        allowManualInput={true}
                        location={party.address}
                        onUpdated={address => {
                          address = {
                            line_1: address.address_line_1,
                            line_2: address.address_line_2,
                            city: address.city,
                            state: address.state,
                            country: address.country,
                            country_code: address.country_short,
                            postal_code: address.postal_code,
                            raw: address.raw
                          }
                          party.address = address
                          this.setState({party})
                        }}
                      />
                    </div>
                  </div>
                </div>

                {
                  party.type == "client" &&
                  <>
                    <p className="my-5 text-muted text-center">Bulk Upload Details</p>
                    <div className="form-group row mb-5">
                      <CodeInput
                        placeholder={"Additional Declaration Type"}
                        listKey={"AdditionalDeclarationType"}
                        value={party.additional_declaration_type}
                        onSelected={code => {
                          party.additional_declaration_type = code
                          this.setState({ party })
                        }}
                      />
                    </div>

                    <div className="form-group row mb-5">
                      <CodeInput
                        placeholder={"Preferred Payment Method"}
                        listKey={"TaxPaymentMethod"}
                        value={party.preferred_payment_method}
                        onSelected={code => {
                          party.preferred_payment_method = code
                          this.setState({ party })
                        }}
                      />
                    </div>

                    <div className="form-group row mb-5">
                      <CodeInput
                        placeholder={"Customs Office Of Lodgement"}
                        listKey={"NationalCustomsOffice"}
                        value={party.customs_office_of_lodgement}
                        onSelected={code => {
                          party.customs_office_of_lodgement = code
                          this.setState({ party })
                        }}
                      />
                    </div>

                    <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="form-label ">UNLOCODE</label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        value={party.unlocode}
                        onChange={e => {
                          party.unlocode = e.target.value || null
                          this.setState({ party })
                        }}
                      />
                    </div>

                    <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="form-label ">Customs Duty Payer Identification Number</label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        value={party.person_paying_customs_duty}
                        onChange={e => {
                          party.person_paying_customs_duty = e.target.value || null
                          this.setState({ party })
                        }}
                      />
                    </div>
                  </>
                }

                {
                  party.type == "declarant" &&
                  <>
                    <div className="fv-row mt-2 mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="form-label ">Update ROS Digital Cert</label>
                      <div className="dropzone dropzone-default">
                        <CustomDropzone
                          deletable={true}
                          onUploaded={file => {
                            party.certificate = party.certificate || {}
                            party.certificate.file = file.id
                            this.setState({ party })
                          }}
                          onDelete={() => {
                            delete party.certificate
                            this.setState({ party })
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="fv-row mb-3">
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="certificatePassword"
                            placeholder="Certificate Password"
                            value={party.certificate?.password || ""}
                            onChange={e => {
                              if(!e.target.value && !party.certificate?.file){
                                delete party.certificate
                              }else{
                                party.certificate = party.certificate || {}
                                party.certificate.password = e.target.value
                              }
                              this.setState({ party })
                            }}
                          />
                          <label htmlFor="certificatePassword">Certificate Password</label>
                        </div>
                      </div>
                    </div>

                  </>
                }
              </div>
            </div>
            <div className=" d-flex justify-content-end py-6">
              <button
                className="btn btn-primary px-6"
                id="addProductCode"
                onClick={() => this._handleSubmission()}
                disabled={loading}
              >
                {title}
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }

}
