import React from "react"

import { Link, withRouter } from "react-router-dom";

import moment from "moment";

import BaseTable from "./BaseTable"

import Edit from "./cells/Edit";
import Delete from "./cells/Delete";

import Confirmation from "../modal/Confirmation";
import RestrictedCode from "../modal/RestrictedCode";

import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";


class RestrictedCodesTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      mode: props.mode,
      showConfirmationModal: false
    }

    this.table = React.createRef()
  }

  _handleDelete(){
    let { selectedRestrictedCode } = this.state
    Backend.deleteRestrictedCode(selectedRestrictedCode)
    .then(() => {
      this.setState({
        showConfirmationModal: false,
        selectedRestrictedCode: null
      })
      this.table.current.refresh()
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _getColumns(){

    return [
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: restrictedCode => {
          return (
            <span className="text-muted fw-bold text-muted d-block fs-7">
              {moment(restrictedCode.created_at).format('lll')}
            </span>
          )
        },
      },
      {
        Header: 'Code',
        accessor: 'taric.value',
      },
      {
        Header: 'Description',
        accessor: 'taric.name',
        width: 400,
      },
      {
        Header: "Routing",
        id: "routes",
        accessor: restrictedCode => {
          return restrictedCode.routes.join(", ")
        },
      },
      {
        Header: "Form",
        id: "form",
        width: 100,
        accessor: restrictedCode => {
          return General.toTitleCase(restrictedCode.form.subtype)
        },
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        exportable: false,
        accessor: restrictedCode => {

          return (
            <>
              <Edit
                onEditClicked={() => {
                  this.setState({
                    showRestrictedCodeModal: true,
                    selectedRestrictedCode: restrictedCode
                  })
                }}
              />
              <Delete
                onDeleteClicked={() => {
                  this.setState({
                    showConfirmationModal: true,
                    selectedRestrictedCode: restrictedCode
                  })
                }}
              />
            </>
          )
        },
      },
    ]
  }

  render() {
    let {
      mode,
      selectedRestrictedCode,
      showConfirmationModal,
      showRestrictedCodeModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.RestrictedCodes}
          noDataMessage={"No restricted codes found"}
          title={this.props.title}
          columns={columns}
          showFilter={false}
          renderToolbar={() => (
            <>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                data-kt-initialized="1"
                data-bs-original-title=""
                title=""
              >
                 <a
                   className="btn btn-primary btn-active-primary ms-3"
                   onClick={() => this.setState({
                     selectedRestrictedCode: null,
                     showRestrictedCodeModal: true,
                   })}
                 >
                   <span className="svg-icon svg-icon-3">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                       <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                             transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                       <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                     </svg>
                   </span>
                   Add Restricted Code
                 </a>
              </div>
            </>
          )}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
        {
          showConfirmationModal &&
          <Confirmation
            show={showConfirmationModal}
            onHide={() => this.setState({showConfirmationModal: false, selectedRestrictedCode: null})}
            onConfirm={() => this._handleDelete()}
          />
        }
        {
          showRestrictedCodeModal &&
          <RestrictedCode
            show={showRestrictedCodeModal}
            restrictedCode={selectedRestrictedCode}
            onHide={() => this.setState({showRestrictedCodeModal: false, selectedRestrictedCode: null})}
            onUpdated={() => {
              this.table.current.refresh()
              this.setState({showRestrictedCodeModal: false })
            }}
          />
        }
      </>
    )

  }

}

RestrictedCodesTable.defaultProps = {
  showDateRange: true,
  subtitle: "",
}

export default withRouter(RestrictedCodesTable);
