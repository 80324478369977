import React from "react"

import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import PartyModal from "../modal/Party";

export default class Party extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            editable: props.editable,
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state

        if(field.is_required && !field.answer?.party){
            return "Please select an option"
        }
        return null
    }

    _onSelected(party){
        let { field } = this.state
        let fieldData = General.clone(field)
        if(party){
            fieldData.answer = {
                party,
                id: field.answer?.id,
            }
        }else{
            delete fieldData.answer
        }
        this.props.onUpdated(fieldData)
    }

    _getBoxCodes(){
      let { field } = this.state
      let type = field.format.party_type
      if(type === "exporter"){
        return {id: "[3/2]", name: "[3/1]"}
      }
      if(type === "importer"){
        return {id: "[3/2]", name: "[3/1]"}
      }
      if(type === "declarant"){
        return {id: "[3/18]", name: "[3/17]"}
      }
      if(type === "representative"){
        return {id: "[3/20]", name: "[3/19]"}
      }
      if(type === "buyer"){
        return {id: "[3/27]", name: "[3/26]"}
      }
      if(type === "seller"){
        return {id: "[3/25]", name: "[3/24]"}
      }
      return {id: "", name: ""}
    }

    _renderValues(){
      let { field } = this.state
      if(!field.answer?.party){
        return null
      }

      let type = field.format.party_type
      let supportedTypes = [
        "declarant",
        "exporter",
        "importer",
        "buyer",
        "seller",
        "representative"
      ]

      if(supportedTypes.indexOf(type) == -1){
        return null
      }

      let party = field.answer.party
      let boxCodes = this._getBoxCodes()

      return (
        <div className="row" style={{ opacity: 0.5 }}>
          <div className={"col-6"}>
              <label className="mb-2 mt-5">{`EORI Number ${boxCodes.id}`}</label>
              <input
                  type="text"
                  className="form-control"
                  placeholder={"EORI Number"}
                  value={party.identification_number}
                  disabled={true}
              />
          </div>
          <div className={"col-6"}>
              <label className="mb-2 mt-5">{`Name ${boxCodes.name}`}</label>
              <input
                  type="text"
                  className="form-control"
                  placeholder={"Name"}
                  value={party.name}
                  disabled={true}
              />
          </div>
          <div className={"col-12"}>
              <label className="mb-2 mt-5">{`Address`}</label>
              <input
                  type="text"
                  className="form-control"
                  placeholder={"Name"}
                  value={party.address?.raw}
                  disabled={true}
              />
          </div>
        </div>
      )
    }

    render(){
        let {
            field,
            editable,
            showPartyModal
        } = this.state

        let value = field.answer ? {
            value: field.answer.id,
            label: `${field.answer.party?.name || `${field.placeholder}`}`,
        } : null

        return (
            <>
                <AsyncSelect
                    placeholder={`${field.placeholder}`}
                    endpoint={window.Api.Parties}
                    orderBy={"name"}
                    filter={`type=${field.format.party_type}`}
                    isClearable={true}
                    creatable={true}
                    disabled={!editable}
                    className={'custom-async-select__container'}
                    classNamePrefix={'custom-async-select'}
                    value={value}
                    getOptions={options => {
                        return options.map(option => ({
                            value: option.id,
                            label: `${option.name || option.identification_number}`,
                            data: option
                        }))
                    }}
                    onSelected={option => {
                        this._onSelected(option)
                    }}
                    onBlur={e => this.props.onBlur()}
                    onCreated={option => {
                        this.setState({
                            showPartyModal: true,
                        })
                    }}
                />

                { this._renderValues()}
                {
                    showPartyModal &&
                    <PartyModal
                        name={"Party"}
                        show={showPartyModal}
                        forcedPartyType={field.format.party_type}
                        onHide={() => this.setState({showPartyModal: false})}
                        onSubmit={(party) => {
                            this._onSelected(party)
                            this.setState({showPartyModal: false})
                        }}
                    />
                }
            </>
        )
    }
}
