import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';

export default class View extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {

    } = this.state

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a className="btn btn-icon btn-secondary btn-sm me-2"
           onClick={e => this.props.onViewClicked()}
        >
          <i className="fa fa-eye" />
        </a>
      </Tooltip>
    )
  }
}

View.defaultProps = {
  tooltip: ''
}
