import React from "react";

import Flatpickr from "react-flatpickr";
import moment from "moment";

import Input from "./Input";
import CodeInput from "./CodeInput";
import Operations from "./Operations";
import Valuation from "./Valuation";
import Collapsible from "./Collapsible";
import Documents from "./Documents";
import Packages from "./Packages";
import AdditionalProcedures from "./AdditionalProcedures";
import AdditionalInformation from "./AdditionalInformation";
import AdditionalFiscalReferences from "./AdditionalFiscalReferences";
import NationalAdditionalCodes from "./NationalAdditionalCodes";
import TaricAdditionalCodes from "./TaricAdditionalCodes";
import TransportDocuments from "./TransportDocuments";

import Codes from "../common/Codes";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import FormUtils from "../../../utils/Form";


export default class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: props.show,
            ...this._getState(props)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this._getState(nextProps))
    }

    _getState(props){
        return {
            editable: props.editable,
            submission: props.submission,
            product: props.product || FormUtils.getItemTemplate()
        }
    }

    _isFormValid(){
        let {
            product
        } = this.state

        let error = null

        if(!product.name){
            error = "Please enter a name"
        }else if(!product.description){
            error = "Please enter a description"
        }else if(!product.quantity){
            error = "Please enter the quantify"
        }else if(!product.commodity_code.taric){
            error = "Please select a TARIC code"
        }

        if(error){
            Notify.error(error)
            return false
        }

        return true
    }

    _renderInput(type, key, placeholder, required=false){
        let { product, editable } = this.state
        return (
            <Input
                type={type}
                editable={editable}
                className="form-control"
                placeholder={placeholder}
                value={product[key]}
                onChange={value => {
                    product[key] = value
                    this.props.onUpdated(product)
                }}
            />
        )
    }

    _renderCodeInput(listKey, value, placeholder, required=false, onSelected, nameKey="name", queryParams={}, isMulti=false){
      let { editable } = this.state
        return (
            <CodeInput
                placeholder={placeholder}
                listKey={listKey}
                value={value}
                required={required}
                editable={editable}
                onSelected={onSelected}
                nameKey={nameKey}
                queryParams={queryParams}
                isMulti={isMulti}
            />
        )
    }


    render() {
        let {
            product,
            editable,
        } = this.state

      console.log(editable)

        return (
           <div className={"item-form"}>
               {this._renderInput("text", "name", "Name", true)}
               {this._renderInput("text", "description", "Description (6/8)", true)}

               <div className="row">
                   <div className="col-md-4">
                       <div className="fv-row mb-4">
                           {
                               this._renderInput(
                                   "number",
                                   "gross_mass",
                                   "Gross Mass (6/5)",
                                   false
                               )
                           }
                       </div>
                   </div>
                   <div className="col-md-4">
                       <div className="fv-row mb-4">
                           {
                               this._renderInput(
                                   "number",
                                   "net_mass",
                                   "Net Mass (6/1)",
                                   false
                               )
                           }
                       </div>
                   </div>
                   <div className="col-md-4">
                       <div className="fv-row mb-4">
                           {
                               this._renderInput(
                                   "number",
                                   "supplementary_units",
                                   "Supplementary Units (6/2)",
                                   false
                               )
                           }
                       </div>
                   </div>
               </div>


                <div className="accordion" id="shipmentItemSections">
                  <Collapsible id={"taric-codes"} title={"TARIC"}>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="fv-row mb-4">
                                {
                                    this._renderCodeInput(
                                        "NomenclatureCode",
                                        product.commodity_code.taric,
                                        "TARIC Code (6/15)",
                                        true,
                                        option => {
                                            product.commodity_code.taric = option
                                            product.y_codes = []
                                            this.props.onUpdated(product)
                                        },
                                        "value"
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                             key={product.commodity_code.taric?.id}
                             className="fv-row mb-4"
                            >
                                {
                                    this._renderCodeInput(
                                        "CommonDocumentsType",
                                        product.y_codes.map(yCode => yCode.code),
                                        "Y Codes",
                                        false,
                                        codes => {
                                          console.log("***", codes)
                                          product.y_codes = codes?.map(code => {
                                            return {
                                              code
                                            }
                                          }) || []
                                          this.props.onUpdated(product)
                                        },
                                        "value",
                                        {
                                          cites_search_term: "Y",
                                          cites_id: product.commodity_code.taric?.id
                                        },
                                        true
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fv-row mb-4">
                                {
                                    this._renderCodeInput(
                                        "ProcedureCode",
                                        product.procedure,
                                        "Procedure Code (1/10)",
                                        true,
                                        option => {
                                            product.procedure = option
                                            this.props.onUpdated(product)
                                        }
                                    )
                                }
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="fv-row mb-4">
                                {
                                    this._renderCodeInput(
                                        "ProcedureCode",
                                        product.previous_procedure,
                                        "Previous Procedure Code (1/10)",
                                        false,
                                        option => {
                                            product.previous_procedure = option
                                            this.props.onUpdated(product)
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <br/>

                    <TaricAdditionalCodes
                        editable={editable}
                        taricAdditionalCodes={product.commodity_code.taric_additional_codes}
                        onUpdated={taricAdditionalCodes => {
                            product.commodity_code.taric_additional_codes = taricAdditionalCodes
                            this.props.onUpdated(product)
                        }}
                    />

                    <NationalAdditionalCodes
                        editable={editable}
                        nationalAdditionalCodes={product.commodity_code.national_additional_codes}
                        onUpdated={nationalAdditionalCodes => {
                            product.commodity_code.national_additional_codes = nationalAdditionalCodes
                            this.props.onUpdated(product)
                        }}
                    />


                  </Collapsible>

                    <Collapsible id={"valuation"} title={"Product Valuation"}>
                        <Valuation
                            editable={editable}
                            valuation={product.valuation}
                            onUpdated={valuation => {
                                product.valuation = valuation
                                this.props.onUpdated(product)
                            }}
                        />
                    </Collapsible>

                    <Collapsible id={"previous-document"} title={"Previous Document (2/1)"}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="fv-row mb-4">
                                    {
                                        this._renderCodeInput(
                                            "PreviousDocumentType",
                                            product.previous_document_type,
                                            "Previous Document Type",
                                            false,
                                            option => {
                                                product.previous_document_type = option
                                                this.props.onUpdated(product)
                                            }
                                        )
                                    }
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="fv-row mb-4">
                                    {
                                        this._renderInput(
                                            "text",
                                            "previous_document_identifier",
                                            "Previous Document Identifier",
                                            true
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fv-row mb-4">
                                    {
                                        this._renderInput(
                                            "text",
                                            "previous_document_line_id",
                                            "Previous Document Line ID",
                                            true
                                        )
                                    }
                                </div>
                            </div>
                        </div>


                    </Collapsible>

                    <Collapsible id={"dates-places"} title="Dates & Places">
                        <div className="row">
                            <div className="col-md-6">
                                {
                                    this._renderCodeInput(
                                        "CountryCodesEU",
                                        product.dates_and_places.destination_country,
                                        "Destination Country (5/8)",
                                        false,
                                        option => {
                                            product.dates_and_places.destination_country = option
                                            this.props.onUpdated(product)
                                        }
                                    )
                                }
                            </div>
                            <div className="col-md-6">
                                {
                                    this._renderCodeInput(
                                        "DestinationRegionCode",
                                        product.dates_and_places.destination_region,
                                        "Destination Region (5/9)",
                                        false,
                                        option => {
                                            product.dates_and_places.destination_region = option
                                            this.props.onUpdated(product)
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="fv-row mb-3">
                                    {
                                        this._renderCodeInput(
                                            "CountryCodesFullList",
                                            product.dates_and_places.dispatch_country,
                                            "Dispatch Country (5/14)",
                                            false,
                                            option => {
                                                product.dates_and_places.dispatch_country = option
                                                this.props.onUpdated(product)
                                            }
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fv-row mb-3">
                                    {
                                        this._renderCodeInput(
                                            "CountryCodesFullList",
                                            product.dates_and_places.origin_country,
                                            "Origin Country (5/15)",
                                            false,
                                            option => {
                                                product.dates_and_places.origin_country = option
                                                this.props.onUpdated(product)
                                            }
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="fv-row mb-3">
                                    {
                                        this._renderCodeInput(
                                            "CountryCodesFullList",
                                            product.dates_and_places.preferential_origin_country,
                                            "Preferential Origin Country (5/16)",
                                            false,
                                            option => {
                                                product.dates_and_places.preferential_origin_country = option
                                                this.props.onUpdated(product)
                                            }
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fv-row mb-3">
                                    <label className={"mb-2"}>Acceptance Date (5/31)</label>
                                    <Flatpickr
                                        className="form-control "
                                        value={product.dates_and_places?.acceptance_date}
                                        placeholder={"Acceptance Date"}
                                        style={{ height: 47.5 }}
                                        options={{
                                            enableTime: false,
                                            noCalendar: false,
                                            dateFormat: "Y-m-d"
                                        }}
                                        disabled={!editable}
                                        onChange={dates => {
                                            product.dates_and_places.acceptance_date = moment(dates[0]).format('YYYY-MM-DD')
                                            this.props.onUpdated(product)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Collapsible>

                    <Collapsible id={"operations"} title={"Additions & Deductions (4/9)"}>

                        <Operations
                            editable={editable}
                            operations={product.valuation.operations}
                            onUpdated={operations => {
                                product.valuation.operations = operations
                                this.props.onUpdated(product)
                            }}
                        />

                    </Collapsible>

                    <Collapsible id={"document"} title={"Documents (2/3)"}>

                        <Documents
                            editable={editable}
                            documents={product.documents}
                            onUpdated={documents => {
                                product.documents = documents
                                this.props.onUpdated(product)
                            }}
                        />

                    </Collapsible>

                    <Collapsible id={"packages"} title={"Packages"}>

                        <Packages
                            editable={editable}
                            packages={product.packages}
                            onUpdated={documents => {
                                product.packages = documents
                                this.props.onUpdated(product)
                            }}
                        />

                    </Collapsible>

                    <Collapsible id={"additional-procedure"} title={"Additional Procedures (1/11)"}>

                        <AdditionalProcedures
                            editable={editable}
                            additionalProcedures={product.additional_procedures}
                            onUpdated={additionalProcedures => {
                                product.additional_procedures = additionalProcedures
                                this.props.onUpdated(product)
                            }}
                        />

                    </Collapsible>

                    <Collapsible id={"additional-information"} title={"Additional Information (2/2)"}>

                        <AdditionalInformation
                            editable={editable}
                            additionalInformation={product.additional_information}
                            onUpdated={additionalInformation => {
                                product.additional_information = additionalInformation
                                this.props.onUpdated(product)
                            }}
                        />

                    </Collapsible>

                    <Collapsible id={"additional-fiscal-references"} title={"Additional Fiscal References (3/40)"}>

                        <AdditionalFiscalReferences
                            editable={editable}
                            additionalFiscalReferences={product.additional_fiscal_references}
                            onUpdated={additionalFiscalReferences => {
                                product.additional_fiscal_references = additionalFiscalReferences
                                this.props.onUpdated(product)
                            }}
                        />

                    </Collapsible>

                    <Collapsible id={"transport-documents"} title={"Transport Documents"}>

                        <TransportDocuments
                            editable={editable}
                            transportDocuments={product.transport_documents}
                            onUpdated={transportDocuments => {
                                product.transport_documents = transportDocuments
                                this.props.onUpdated(product)
                            }}
                        />

                    </Collapsible>

                </div>
           </div>
        )
    }


}

Form.defaultProps = {
    declaration: true
}
