import React, {Component} from 'react';

import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'

import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

export default class SmartList extends Component  {

  constructor(props){
    super(props);
    this.state = this._getState(props)
  }

  componentDidMount(){
    if(this.props.endpoint){
      this._loadItems()
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    let items = []
    if(props.items){
      items = props.items.map(item => {
        item._uid = item.id ? item.id.toString() : item._uid
        if(!item._uid){
          item._uid = General.uuid()
        }
        return item
      })
    }

    return {
      items,
      endpoint: props.endpoint,
    }
  }

  _loadItems(){
    FetchHelper.get(this.props.endpoint)
    .then(items => this.props.onLoadedItems(items))
    // .catch(error => this.props.onLoadItemsError(error))
  }

  _renderInput(items, index){
    let item = items[index]
    if(this.props.renderItem){
      return this.props.renderItem(item, index)
    }

    return (
      <input
        type="text"
        className="form-control form-control-lg"
        value={ this.props.value(item, index) }
        disabled={this.props.disabled(item, index)}
        onChange={e => this.props.onChange(e.target.value, item, index)}
      />
    )
  }

  _renderDelete(item, index){
    let { items } = this.state
    if(!this.props.canDelete){
      return null
    }

    if(this.props.renderDelete){
      return this.props.renderDelete(item, index)
    }

    return (
      <a
        href="javascript:;"
        className="btn btn-sm btn-label-danger btn-bold pull-right"
        style={{ height: 38,  }}
        onClick={() => {
          items.splice(index, 1)
          this.props.onUpdated(items)
        }}
      >
        <i
          className="la la-trash-o align-select-centers"
          style={{
            marginRight: 0,
            marginTop: 4
          }}
        />
      </a>
    )
  }

  _onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.props.onUpdated(items)
  }

  _renderItem(items, item, index){
    return (
      <Draggable key={item._uid} draggableId={item._uid} index={index}>
        {(provided, snapshot) => (
          <div
            className="row m-0 mb-3"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div className="form-group row mb-5">
              { this.props.showItemName &&
              <div className="col-md-6">
                <label>{ `${this.props.itemName} #${index + 1}` }</label>
              </div>
              }
              <div className="col-md-6 text-end">
                {
                  items.length > this.props.minimumItems &&
                  this._renderDelete(item, index)
                }
              </div>
            </div>
            <div className="form-group row mb-5">
              { this._renderInput(items, index)}
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  _renderItems(){
    let { items } = this.state

    if (items.length === 0) {
      return null
    }

    if(this.props.draggable){
      return (
        <DragDropContext onDragEnd={result => this._onDragEnd(result)}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => this._renderItem(items, item, index))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    }

    return items.map((item, index) => {
      return (
        <div
          className="row m-0 mb-3"
        >
          <div className="form-group row mb-5">
            { this.props.showItemName &&
            <div className="col-md-6">
              <label>{ `${this.props.itemName} #${index + 1}` }</label>
            </div>
            }
            <div className="col text-end">
              {
                items.length > this.props.minimumItems &&
                this._renderDelete(item, index)
              }
            </div>
          </div>
          <div className="form-group row mb-5">
            { this._renderInput(items, index)}
          </div>
        </div>
      )
    })
  }


  render(){
    let {
      items
    } = this.state

    return (
      <div key={items.length}>

        { this.props.title &&
          <h4 className="mb-4">{ this.props.title }</h4>
        }

        { this._renderItems() }
        {
          (items.length < this.props.maximumItems && this.props.canAdd) &&
          <div className="row">
            <div className="col-md-12 mb-10">
              <a
                href="javascript:;"
                data-repeater-create=""
                className="btn btn-light-primary"
                onClick={() => {
                  this.props.onAddItemPressed()
                }}
              >
                <i className="la la-plus"/>{`Add ${this.props.itemName}`}
              </a>
            </div>
          </div>
        }
      </div>
    )
  }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#fbfbfb" : 'transparent',
  // padding: grid,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  // padding: grid,
  background: isDragging ? "rgba(93, 120, 255, 0.4)" : "transparent",
  width: '100%',
  outline: 'none',
  ...draggableStyle
});


SmartList.defaultProps = {
  minimumItems: -1,
  maximumItems: 999,
  itemName: 'Item',
  canDelete: true,
  canAdd: true,
  value:() => "text",
  disabled:  () => true,
  onChange: () => null,
  onAddItemPressed: () => null,
  showItemName: false,
  draggable: false
}
