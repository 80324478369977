import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";

import CodeModal from "../modal/Code";

import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Confirmation from "../modal/Confirmation";

class CodesTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.table = React.createRef()
  }

  _handleDelete(code){
    Backend.deleteCode(code)
    .then(() => {
      Notify.success("Code Deleted")
      this.setState({selectedCode: null, showDeleteConfirmationModal: false})
      this.table.current.refresh()
    })
    .catch(e => Notify.error(e.message))
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Name',
        id: 'name',
        width: 200,
        accessor: code => code.name,
      },
      {
        Header: 'Value',
        id: 'value',
        width: 200,
        accessor: code => code.value,
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: code => code.description
      },
      {
        Header: '',
        id: '',
        width: 100,
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let code = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
                <Edit
                  onEditClicked={() => {
                    this.setState({ selectedCode: code, showCodeModal: true })
                  }}
                />
                <Delete
                  onDeleteClicked={() => {
                    this.setState({ selectedCode: code, showDeleteConfirmationModal: true })

                  }}
                />
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {
      loading,
      selectedCode,
      showCodeModal,
      showDeleteConfirmationModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Codes}`}
          params={{ list_id: this.props.listId }}
          noDataMessage={"No codes found"}
          title={this.props.title}
          columns={columns}
          defaultSorted={[
            {
              id: "name",
              desc: false
            }
          ]}
          renderToolbar={() => (
            <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                 data-kt-initialized="1" data-bs-original-title="" title="">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ showCodeModal: true, selectedCode: null })}
              >
                Add Code
              </button>
            </div>
          )}
        />
        { showCodeModal &&
          <CodeModal
            show={showCodeModal}
            code={selectedCode}
            listId={this.props.listId}
            onHide={() => this.setState({ showCodeModal: false, selectedCode: null})}
            onUpdated={() => {
              this.table.current.refresh()
              this.setState({
                showCodeModal: false,
                selectedCode: null
              })
            }}
          />
        }
        {
          showDeleteConfirmationModal &&
          <Confirmation
            show={showDeleteConfirmationModal}
            onHide={() => this.setState({showDeleteConfirmationModal: false, selectedCode: null})}
            onConfirm={() => this._handleDelete(selectedCode)}
          />
        }

      </>
    )

  }

}

export default withRouter(CodesTable);
