import React from "react";

import CodeInput from "./CodeInput"

import SmartList from "../common/SmartList";
import Codes from "../common/Codes";

export default class TaricAdditionalCodes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            taricAdditionalCodes: props.taricAdditionalCodes
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderTaricAdditionalCode(taricAdditionalCode, index){
        let {
            editable,
            taricAdditionalCodes
        } = this.state

        return (
            <div className="form-group row mb-5">
                <div className="col-md-12">
                    <CodeInput
                        placeholder={"TARIC Additional Code"}
                        listKey={"TaricAdditionalCode"}
                        value={taricAdditionalCode.code}
                        editable={editable}
                        onSelected={option => {
                            taricAdditionalCodes[index].code = option
                            this.props.onUpdated(taricAdditionalCodes)
                        }}
                    />
                </div>
            </div>
        )
    }


    render() {
        let {
            editable,
            taricAdditionalCodes,
        } = this.state

        let itemName = "TARIC Additional Code"
        return (
            <SmartList
                items={taricAdditionalCodes}
                showItemName={true}
                canDelete={editable}
                canAdd={editable}
                itemName={itemName}
                renderItem={(item, index) => this._renderTaricAdditionalCode(item, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            taricAdditionalCodes.splice(index, 1)
                            this.props.onUpdated(taricAdditionalCodes)
                        }}
                    >
                        <i className="la la-trash-o fs-3">
                        </i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    taricAdditionalCodes = items
                    this.props.onUpdated(taricAdditionalCodes)
                }}
                onAddItemPressed={() => {
                    taricAdditionalCodes.push({})
                    this.props.onUpdated(taricAdditionalCodes)
                }}
            />
        )
    }


}

TaricAdditionalCodes.defaultProps = {

}
