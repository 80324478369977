import React from "react"

import {Link, withRouter} from "react-router-dom";
import moment from "moment";

import StatesTable from "./StatesTable";
import BaseTable from "./BaseTable"

import View from "./cells/View";
import Delete from "./cells/Delete";
import VatSummary from "./cells/VatSummary";

import Select from "../common/Select";
import Separator from "../common/Separator";

import Submission from "../modal/Submission";
import Declaration from "../modal/Declaration";

import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";


const CREATOR_FILTERS = {
  name: {
    api: 'company_id',
    display: 'Company',
  },
  endpoint: {
    url:`${window.Api.Companies}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

export const STATUS_FILTERS = {
  isMulti: true,
  name: {
    api: 'statuses',
    display: 'Status',
  },
  values: [
    {
      label: 'Draft',
      value: 'draft',
    },
    {
      label: 'Complete',
      value: 'complete',
    },
    {
      label: 'Processing',
      value: 'processing',
    },
    {
      label: 'Registered',
      value: 'registered',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
    {
      label: 'Accepted',
      value: 'accepted',
    },
    {
      label: 'Under Review',
      value: 'under_review',
    },
    {
      label: 'Insufficient Funds',
      value: 'insufficient_funds',
    },
    {
      label: 'Released',
      value: 'released',
    },
  ],
}

export const ROUTE_FILTERS = {
  isMulti: true,
  name: {
    api: 'routes',
    display: 'Route',
  },
  values: [
    {
      label: 'Red',
      value: 'red',
    },
    {
      label: 'Green',
      value: 'green',
    },
    {
      label: 'Orange',
      value: 'orange',
    },
    {
      label: 'Yellow',
      value: 'yellow',
    }
  ],
}

const CONSIGNOR_FILTERS = {
  isMulti: true,
  name: {
    api: 'consignor_ids',
    display: 'Consignor',
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: 'type=exporter',
    accessor: ''
  }
}

const CONSIGNEE_FILTERS = {
  isMulti: true,
  name: {
    api: 'consignee_ids',
    display: 'Consignee',
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: 'type=importer',
    accessor: ''
  }
}

const CLIENT_FILTERS = {
  isMulti: true,
  name: {
    api: 'client_ids',
    display: 'Client',
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: 'type=client',
    accessor: ''
  }
}

export const SUBTYPE_FILTERS = {
  isMulti: true,
  name: {
    api: 'form_subtypes',
    display: 'Type',
  },
  values: [
    {
      label: 'H1',
      value: 'H1',
    },
    {
      label: 'H2',
      value: 'H2',
    },
    {
      label: 'H3',
      value: 'H3',
    },
    {
      label: 'H4',
      value: 'H4',
    },
    {
      label: 'H5',
      value: 'H5',
    },
    {
      label: 'H6',
      value: 'H6',
    },
    {
      label: 'H7',
      value: 'H7',
    },
    {
      label: 'I1',
      value: 'I1',
    },
    {
      label: 'G3/G4',
      value: 'G4G3',
    },
    {
      label: 'B1',
      value: 'B1',
    },
    {
      label: 'B2',
      value: 'B2',
    },
    {
      label: 'B4',
      value: 'B4',
    },
    {
      label: 'C1 C&F',
      value: 'C1 C&F',
    },
    {
      label: 'C1 B&E',
      value: 'C1 B&E',
    },
  ],
}

const FILTERS = [CREATOR_FILTERS, CONSIGNOR_FILTERS, CONSIGNEE_FILTERS, CLIENT_FILTERS, STATUS_FILTERS, ROUTE_FILTERS, SUBTYPE_FILTERS]

const COMPARISONS = [
  {
    label: "EQUALS",
    value: "exact"
  },
  {
    label: "CONTAINS",
    value: "contains"
  },
  {
    label: "STARTS WITH",
    value: "startswith"
  },
  {
    label: "ENDS WITH",
    value: "endswith"
  }
]


class SubmissionsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live,
      mode: props.mode,
      imports: props.imports,
      formFields: [],
      advancedFilters: []
    }

    this.table = React.createRef()
  }

  _goTo(submission, edit=true){
    this.props.history.push(`/submission/${submission.id}`)
  }

  _applyAdvancedFilters(){
    let { advancedFilters } = this.state

    let advancedFiltersQuery = []
    advancedFilters.forEach((filter, i) => {
      let fieldId = filter.field?.id
      if(!fieldId){
        return
      }
      advancedFiltersQuery.push({
        type: "field",
        field_id: fieldId,
        value: filter.value,
        match: filter.comparison.value
      })
    });

    let advancedFiltersQueryValue = encodeURIComponent(
      JSON.stringify(advancedFiltersQuery)
    )
    this.setState({ advancedFiltersQueryValue }, () => {
      this.table.current.refresh()
    })
  }

  _loadFormFields(){
    let { formSubType } = this.state
    if(!formSubType){
      return
    }

    Backend.getFields(formSubType.value, "text,code,number,date,datetime")
    .then(fields => {
      this.setState({
        formFields: fields,
        advancedFilters: [{
          _id: General.uuid(),
          comparison: COMPARISONS[0]
        }],
        isLoadingFields: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _handleDelete(submission){
    Backend.deleteSubmission(submission.id).then(() => {
      Notify.success("Declaration Deleted.")
      this.setState({showConfirmationModal: false, selectedSubmission: null})
    }).catch(e => Notify.error(e.message))
  }

  _renderSubmittedActions(submission){
    return (
      <div className={"text-end"}>
        <View
          onViewClicked={() => {
            this.setState({
              selectedSubmission: submission, showDeclarationModal: true
            })
          }}
        />
        {
          submission.status === "released" &&
          <VatSummary
            onVatSummaryClicked={() => this.setState({selectedSubmission: submission, showSubmissionSummaryModal: true})}
          />
        }
      </div>
    )
  }

  _getColumns(){

    const COLUMN_ID = {
      Header: 'ID',
      id: 'id',
      width: 200,
      accessor: submission => {
        return (
            <>
              <span className="text-hover-primary text-dark fs-8">
                <span className={"text-dark"}>ID:</span> <span className="">{submission.id}</span>
              </span>
              {
                submission.mrn &&
                <span className="text-muted fw-bold text-muted d-block fs-8">
                  MRN: <span>{submission.mrn}</span>
                </span>
              }
              {
                submission.external_id &&
                <span className="text-muted fw-bold text-muted d-block fs-8">
                  UCR: <span>{submission.external_id}</span>
                </span>
              }
            </>
        )
      }
    }

    const COLUMN_LRN = {
      Header: 'LRN',
      id: 'lrn',
      show: false,
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_MRN = {
      Header: 'MRN',
      id: 'mrn',
      show: false,
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_EXTERNAL_ID = {
      Header: 'Unique Customer Reference',
      id: 'external_id',
      show: false,
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_ADDITIONAL_EXTERNAL_IDS = {
      Header: 'Additional Customer References',
      id: 'additional_external_ids',
      show: false,
      type: "array",
      format: "str",
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_NAME = {
      Header: 'Name',
      accessor: 'name',
      width: 200
    }

    const COLUMN_CREATED_BY = {
      Header: 'Created By',
      id: 'company_member.user.first_name',
      accessor: submission => {

        let companyMember = submission.company_member
        if(!companyMember){
          return "-"
        }
        return (
          <>
            <a className="text-dark fw-bolder text-hover-primary fs-6">
              {companyMember.user.first_name} {companyMember.user.last_name}
            </a>
            <span className="text-muted fw-bold text-muted d-block fs-7">
              {companyMember.company.name}
            </span>
          </>
        )
      },
    }

    const COLUMN_PARTIES = {
      Header: 'Parties',
      id: 'consignor.name',
      accessor: submission => (
          <>
              <a className="text-dark text-hover-primary">
                CNOR: {General.snakeCaseToTitleCase(submission.consignor?.name) || '-'}
              </a>
              <br/>
              <a className="text-muted text-hover-primary">
                CNEE: {General.snakeCaseToTitleCase(submission.consignee?.name) || '-'}
              </a>
          </>

      ),
    }



    const COLUMN_STATUS = {
      Header: "Status",
      id: "status",
      width: 150,
      accessor: submission => {
        let className = ''
        if(submission.route === "green"){
          className = 'svg-icon-light-success'
        }else if(submission.route === "red"){
          className = 'svg-icon-light-danger'
        }else if(submission.route === "yellow"){
          className = 'svg-icon-light-warning'
        }else if(submission.route === "orange"){
          className = 'svg-icon-light-warning'
        }
        let route = (
            <span className={`svg-icon ${className}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                  <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
                </svg>
            </span>
        )

        if(["draft", "complete", "processing","rejected"].indexOf(submission.status) > -1){
          route = null
        }

        return (
          <>
              <span className="text-dark text-hover-primary fs-6">
                {route} {General.snakeCaseToTitleCase(submission.status)}
              </span>
          </>
        )
      },
    }

    const COLUMN_CLIENT = {
      Header: 'Client',
      id: 'client.name',
      accessor: submission => {
        let client = submission.client
        if(!client){
          return "-"
        }

        return (
            <span className="text-dark text-hover-primary fs-6">
              {client.name}
            </span>
        )
      },
    }

    const COLUMN_TYPE = {
      Header: "Type",
      id: "form.subtype",
      width: 70,
      accessor: submission => (
          <>
            <a className="text-dark fw-bolder text-hover-primary fs-6">
              {General.snakeCaseToTitleCase(submission.form.subtype)}
            </a>
            <span className="text-muted fw-bold text-muted d-block fs-7 text-uppercase">
              {General.snakeCaseToTitleCase(submission.form.type)}
            </span>
          </>
      ),
    }

    const COLUMN_LAST_UPDATE = {
      Header: 'Last Update',
      id: 'latest_state.created_at',
      width: 180,
      accessor: submission => {

        let state = submission.latest_state
        if(!state){
          return "-"
        }

        let updatedBy = "Revenue"
        if(state.company_member){
          let user = state.company_member.user
          updatedBy = `${user.first_name} ${user.last_name}`
        }

        return (
            <>
              <a className="text-dark fw-bolder text-hover-primary fs-6">
                {updatedBy}
              </a>
              <span className="text-muted fw-bold text-muted d-block fs-7">
                {moment(state.created_at).format('lll')}
              </span>
            </>
        )
      },
    }

    const COLUMN_LAST_UPDATE_MESSAGE = {
      Header: 'Latest Update',
      id: 'latest_state.message',
      width: 180,
      show: false,
      sortable: false,
      exportable: true,
    }

    const COLUMN_LAST_UPDATE_BR_ERROR = {
      Header: 'Latest Error',
      id: 'latest_state.message',
      type: "br_error",
      format: "str",
      width: 180,
      show: false,
      sortable: false,
      exportable: true,
    }


    const COLUMN_ACTIONS = {
      Header: '',
      id: '',
      sortable: false,
      exportable: false,
      Cell: rowInfo => {
        let submission = rowInfo.original
        return this._renderSubmittedActions(submission)
      }
    }

    return [
      COLUMN_ID,
      COLUMN_LRN,
      COLUMN_MRN,
      COLUMN_EXTERNAL_ID,
      COLUMN_ADDITIONAL_EXTERNAL_IDS,
      COLUMN_TYPE,
      COLUMN_CLIENT,
      COLUMN_PARTIES,
      COLUMN_STATUS,
      COLUMN_CREATED_BY,
      COLUMN_LAST_UPDATE,
      COLUMN_LAST_UPDATE_MESSAGE,
      COLUMN_LAST_UPDATE_BR_ERROR,
      COLUMN_ACTIONS
    ]
  }

  _renderStates(submission){
    return (
      <StatesTable
        submissionID={submission.id}
      />
    )
  }

  _renderAdvancedFilters(){
    let { formFields, formSubType, advancedFilters } = this.state

    return (
      <>
        <Separator text="Advanced Filters"/>
        <Select
          isClearable={true}
          value={formSubType}
          options={SUBTYPE_FILTERS.values}
          placeholder={"Please select a form"}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          className={'filter-solid custom-async-select__container'}
          classNamePrefix={'custom-async-select'}
          onSelected={option => {
            this.setState({
              fields: [],
              advancedFilters: [],
              formSubType: option,
            }, () => this._loadFormFields())
          }}
        />

        { formFields.length > 0 &&
          <>
            { advancedFilters.map((filter, i) => {
                return (
                  <div key={filter._id} className="row">
                    <div className="col-11">
                      <div className="row">
                        <div className="col-6">
                          <Select
                            options={formFields}
                            placeholder={"Please select a field"}
                            getOptionLabel={option => {
                              let label = option.title
                              if(option.box_code){
                                label += ` [${option.box_code}]`
                              }
                              return label
                            }}
                            getOptionValue={option => option.id}
                            value={filter.field}
                            className={'filter-solid custom-async-select__container'}
                            classNamePrefix={'custom-async-select'}
                            onSelected={field => {
                              advancedFilters[i].field = field
                              this.setState({ advancedFilters })
                            }}
                          />
                        </div>

                        <div className="col-2">
                          <Select
                            options={COMPARISONS}
                            isDisabled={true}
                            placeholder={"Please select an option"}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            value={filter.comparison?.value}
                            className={'filter-solid custom-async-select__container'}
                            classNamePrefix={'custom-async-select'}
                            onSelected={option => {
                              advancedFilters[i].comparison = option
                              this.setState({ advancedFilters })
                            }}
                          />
                        </div>

                        <div className="col-4">
                          <input
                            type="text"
                            style={{height: 47.8 }}
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Please type a value"
                            value={filter.value}
                            onChange={e => {
                              advancedFilters[i].value = e.target.value || null
                              this.setState({ advancedFilters })
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-1 text-end">
                      {advancedFilters.length > 1 &&
                        <Delete
                          onDeleteClicked={() => {
                            let newAdvancedFilters = General.clone(advancedFilters)
                            newAdvancedFilters.splice(i, 1)
                            this.setState({ advancedFilters: newAdvancedFilters })
                          }}
                        />
                      }
                    </div>
                  </div>
                )
              })
            }

            <div className="row">
              <div className="col">
                <button
                  className="btn btn-light-primary btn-sm"
                  onClick={() => {
                    advancedFilters.push({
                      _id: General.uuid(),
                      comparison: COMPARISONS[0]
                    })
                    this.setState({ advancedFilters })
                  }}
                >
                  Add Filter
                </button>
              </div>
              <div className="col text-end">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    this._applyAdvancedFilters()
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </>
        }
      </>
    )
  }

  render() {
    let {
      mode,
      selectedSubmission,
      showDeclarationModal,
      showConfirmationModal,
      advancedFiltersQueryValue,
      showSubmissionSummaryModal,
      showDocumentsRequestedModal,
      showDocumentsPresentationModal
    } = this.state

    const columns = this._getColumns()

    let showPagination = true
    let toolbar= null
    let noDataMessage = "No declarations found"
    let showDateRange = true
    let endpoint = window.Api.Submissions
    let sortId = "created_at"

    if(mode === "latest"){
      endpoint += "?template=false"
      showPagination = false
      sortId = "latest_state.created_at"
      toolbar = (
        <Link
          to="/import-submissions"
          className="btn btn-light-primary btn-active-primary"
        >
          <span className="svg-icon svg-icon-3">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                    transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
              <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
            </svg>
          </span>
          View All
        </Link>
      )
    }
    else if(mode === "template"){
      showDateRange = false
      noDataMessage = "No templates found"
      endpoint += "?template=true"
    }
    else if(mode === "draft"){
      showDateRange = false
      noDataMessage = "No drafts found"
      endpoint += "?status=draft&template=false"
    }
    else if(mode === "declaration"){
      endpoint += "?template=false"
      sortId = "latest_state.created_at"
    }

    let bulkUpload = this.props.bulkUpload
    let exportable = bulkUpload != null
    if(bulkUpload){
      endpoint += `&bulk_upload_id=${bulkUpload.id}`
    }

    if(advancedFiltersQueryValue != null){
      endpoint += `&advanced_filters=${advancedFiltersQueryValue}`
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={noDataMessage}
          title={this.props.title}
          subtitle={this.props.subtitle}
          showPagination={showPagination}
          columns={columns}
          sendExportAsEmail={true}
          exportFilename={bulkUpload?.external_id}
          exportButtonsEnabled={exportable}
          filters={FILTERS}
          defaultSorted={[
            {
              id: sortId,
              desc: true
            }
          ]}
          dateRange={showDateRange}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                {toolbar}
              </div>
            </>
          )}
          SubComponent={(row) => {
            return this._renderStates(row.original)
          }}
          renderFilterSubMenuFooter={() => this._renderAdvancedFilters()}
          collapseOnDataChange={false}
          expandedKey="id"
          showFiltersInSubMenu
        />

        {
          showSubmissionSummaryModal &&
          <Submission
            show={showSubmissionSummaryModal}
            submission={selectedSubmission}
            onHide={() => this.setState({showSubmissionSummaryModal: false, selectedSubmission: null})}
          />
        }

        {
          showDeclarationModal &&
          <Declaration
            show={showDeclarationModal}
            editable={false}
            submission={selectedSubmission}
            onClose={() => {
              this.setState({
                showDeclarationModal: false,
                selectedSubmission: null,
                declarationEditable: false
              })
            }}
          />
        }
      </>
    )

  }

}

SubmissionsTable.defaultProps = {
  showDateRange: true,
  subtitle: "",
  mode: "declaration"
}

export default withRouter(SubmissionsTable);
