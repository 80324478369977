import React from "react"
import LocationInput from "../common/LocationInput";
import CustomDropzone from "../common/CustomDropzone";
import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend";

export default class PartyDetail extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: props.data
    }
  }

  render() {
    let {
      data
    } = this.state

    let address = data.address
    let location = (address && address.line1) ? {
      ...address,
      raw: `${address.line1}, ${address.line2}, ${address.city}, ${address.state}, ${address.country}`,
      line_1: address.line1,
      line_2: address.line2,
    } : null

    return (
      <>
        <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
          <label className="form-label ">Name</label>
          <input
            name="business_name"
            className="form-control form-control-lg form-control-solid"
            value={data.name}
            onChange={e => {
              data.name = e.target.value
              this.props.onUpdated(data)
            }}
          />
          <div className="fv-plugins-message-container invalid-feedback"></div>
        </div>

        <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
          <label className="form-label ">Identification Number</label>
          <input
            name="business_name"
            className="form-control form-control-lg form-control-solid"
            value={data.identification_number}
            onChange={e => {
              data.identification_number = e.target.value
              this.props.onUpdated(data)
            }}
          />
          <div className="fv-plugins-message-container invalid-feedback"></div>
        </div>

        <div className="row mb-10" bis_skin_checked="1">
          <label className="form-label ">Address</label>
          <LocationInput
            className="form-control form-control-lg form-control-solid"
            placeholder=""
            allowManualInput={true}
            location={location}
            onUpdated={address => {
              address = {
                line1: address.address_line_1,
                line2: address.address_line_2,
                city: address.city,
                state: address.state,
                country: address.country,
                country_code: address.country_short,
                postal_code: address.postal_code,
                raw: `${address.address_line_1}, ${address.address_line_2}, ${address.city}, ${address.state}, ${address.country}`
              }
              data.address = address
              this.props.onUpdated(data)
            }}
          />
        </div>
      </>
    )
  }

}