import React from "react";
import General from "../../../utils/General";
import Form from "../../../utils/Form";

export default class Collapsible extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id || General.uuid(),
            title: props.title
        }
    }

    render() {
        let {
            id,
            title
        } = this.state

        let headerId = `${id}_header`
        let bodyId = `${id}_body`

        return (
            <div className="accordion-item">
                <h2 className="accordion-header" id={headerId}>
                    <button
                        className="accordion-button fs-4 fw-semibold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${bodyId}`}
                        aria-expanded="false"
                        aria-controls={bodyId}
                    >
                        {title}
                    </button>
                </h2>
                <div
                    id={bodyId}
                    className="accordion-collapse collapse"
                    aria-labelledby={headerId}
                    data-bs-parent="#shipmentItemSections"
                >
                    <div className="accordion-body">
                        {this.props.children}
                    </div>
                </div>
            </div>

        )
    }


}

Collapsible.defaultProps = {
    declaration: true
}