import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Select from "../common/Select";

const ROLE_OPTIONS = [
  { value: "admin", label: "Admin" },
  { value: "staff", label: "Staff" },
]

export default class CompanyMember extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      company: props.company,
      companyMember: props.companyMember || {user: {}, role: "admin", company: props.company.id}
    }
  }

  componentDidMount() {

  }

  _handleChangeUser(e) {
    let companyMember = this.state.companyMember
    companyMember.user[e.target.name] = e.target.value
    this.setState({ companyMember, error: null })
  }

  _handleSubmission(){
    let {
      companyMember
    } = this.state

    if(companyMember.user.id){
      this._updateCompanyMember(companyMember)
    }else{
      this._addCompanyMember(companyMember)
    }
  }

  _addCompanyMember(companyMember){
    this.setState({loading: true})
    Backend.addCompanyMember(companyMember).then(party => {
      this.setState({loading: false})
      Notify.success("Company Member Added")
      this.props.onSubmit()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _updateCompanyMember(companyMember){
    this.setState({loading: true})
    Backend.updateCompanyMember(companyMember).then(party => {
      this.setState({loading: false})
      Notify.success("Company Member Updated")
      this.props.onSubmit()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      companyMember,
    } = this.state

    let title = companyMember.user?.id ? "Edit User" : "Create User"
    let buttonTitle = companyMember.user?.id ? "Save Changes" : "Add User"

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-70"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form id="kt_modal_add_user_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll"
                data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header"
                data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px"
                style={{maxHeight: 458}} bis_skin_checked="1"
              >
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="First Name"
                    value={companyMember.user?.first_name}
                    onChange={e => this._handleChangeUser(e)}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Last Name"
                    value={companyMember.user?.last_name}
                    onChange={e => this._handleChangeUser(e)}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>
                {/*<div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">*/}
                {/*  <label className="required fw-semibold fs-6 mb-2">Role</label>*/}
                {/*  <Select*/}
                {/*    value={companyMember.role}*/}
                {/*    options={ROLE_OPTIONS}*/}
                {/*    placeholder="Select A Role"*/}
                {/*    getOptionLabel={role => role.label}*/}
                {/*    getOptionValue={role => role.value}*/}
                {/*    className="form-control h-auto border-0 form-control-solid c-selectbox"*/}
                {/*    onSelected={role => {*/}
                {/*      companyMember.role = role.value*/}
                {/*      this.setState({companyMember})*/}
                {/*    }}*/}
                {/*  />*/}
                {/*  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>*/}
                {/*</div>*/}
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="example@domain.com"
                    value={companyMember.user?.email}
                    onChange={e => this._handleChangeUser(e)}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Password"
                    onChange={e => this._handleChangeUser(e)}
                  />
                </div>
              </div>
              <div className="text-center pt-15" bis_skin_checked="1">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => this.props.onHide()}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  onClick={e => {
                    e.preventDefault()
                    this._handleSubmission()
                  }}
                >
                  <span className="indicator-label">{buttonTitle}</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
              <div bis_skin_checked="1"></div>
            </form>
          </Modal.Body>

        </Modal>
      </>
    )
  }

}