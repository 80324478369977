import React from "react";

import CodeInput from "./CodeInput"

import Codes from "../common/Codes"
import Input from "./Input";

export default class Valuation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            valuation: props.valuation
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }



    render() {
        let {
            editable,
            valuation,
        } = this.state

        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <div className="fv-row mb-3">
                            <Input
                                type="number"
                                value={valuation.amount}
                                placeholder="Item Price (4/14)"
                                onChange={statistical => {
                                    valuation.amount = statistical
                                    this.props.onUpdated(valuation)
                                }}
                                editable={editable}
                            />
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="fv-row mb-3">
                            <Input
                                type="number"
                                value={valuation.statistical}
                                placeholder="Statistical Value (8/6)"
                                onChange={statistical => {
                                    valuation.statistical = statistical
                                    this.props.onUpdated(valuation)
                                }}
                                editable={editable}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="fv-row mb-3">
                            <CodeInput
                                editable={editable}
                                placeholder={"Preference (4/17)"}
                                listKey={"Preference"}
                                value={valuation.preference}
                                onSelected={option => {
                                    valuation.preference = option
                                    this.props.onUpdated(valuation)
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="fv-row mb-3">
                            <CodeInput
                                editable={editable}
                                placeholder={"Indicator (4/13)"}
                                listKey={"ValuationIndicators"}
                                value={valuation.indicator}
                                onSelected={option => {
                                    valuation.indicator = option
                                    this.props.onUpdated(valuation)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="fv-row mb-3">
                            <CodeInput
                                editable={editable}
                                placeholder={"Valuation Method (4/16)"}
                                listKey={"ValuationMethod"}
                                value={valuation.method}
                                onSelected={option => {
                                    valuation.method = option
                                    this.props.onUpdated(valuation)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }


}

Valuation.defaultProps = {

}
