import React from "react"
import LocationInput from "../../components/common/LocationInput";
import CustomDropzone from "../common/CustomDropzone";
import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend";
import PartyDetail from "../customsWindow/PartyDetail";

export default class DeclarantInfo extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: {
        name: "",
        type: "declarant",
        identification_number: "",
        certificate: {password: ""}
      }
    }
  }

  _isFormValid(){
    let {
      data
    } = this.state

    let error = null
    if(!data.name){
      error = "Please enter the declarant name"
    }
    else if(!data.certificate.file){
      error = "Please upload your ROS Digital Cert"
    }
    else if(!data.certificate.password){
      error = "Please enter your certificate password"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _addDeclarant(){
    let {
      data
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({
      isLoading: true,
    })

    Backend.addParty(data).then(party => {
      this.props.onDeclarantAdded()
    }).catch(error => {
      this.setState({
        isLoading: false,
      })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      data,
      isLoading
    } = this.state

    return (
      <>
        <div className="current" data-kt-stepper-element="content">
          <div className="w-100">
            <div className="pb-10 pb-lg-12">
              <h2 className="fw-bolder text-dark">Declarant Details</h2>
              <div className="text-muted fw-bold fs-6">If you need more info, please
                <a href="#" className="link-primary fw-bolder">Contact Support</a>.
              </div>
            </div>

            <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
              <label className="form-label ">Upload your ROS Digital Cert</label>
              <CustomDropzone
                onUploaded={file => {
                  data.certificate.file = file.id
                  this.setState({data})
                }}
              />
            </div>

            <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
              <label className="form-label ">Certificate Password</label>
              <input
                type="password"
                className="form-control form-control-lg form-control-solid"
                value={data.certificate.password}
                onChange={e => {
                  data.certificate.password = e.target.value
                  this.setState({data})
                }}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>

            <PartyDetail
              data={data}
              onUpdated={data => {
                this.setState({data})
              }}
            />

          </div>
        </div>
        <div className="d-flex flex-stack pt-15">
          <div className="mr-2"/>
          <div>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-kt-stepper-action="next"
              disabled={isLoading}
              onClick={e => this._addDeclarant()}
            >
              Continue
              <span className="svg-icon svg-icon-4 ms-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="black"></rect>
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </>
    )
  }

}