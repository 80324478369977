import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class Account extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      account: props.account || {members: [{user:{}}]}
    }
  }

  componentDidMount() {

  }

  _handleSubmission(){
    let {
      account
    } = this.state

    if(account.id){
      this._updateAccount(account)
    }else{
      this._addAccount(account)
    }
  }

  _addAccount(account){
    this.setState({loading: true})
    Backend.addAccount(account).then(party => {
      this.setState({loading: false})
      Notify.success("Account Added")
      this.props.onSubmit()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _updateAccount(account){
    this.setState({loading: true})
    Backend.updateAccount(account).then(party => {
      this.setState({loading: false})
      Notify.success("Account Updated")
      this.props.onSubmit()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _handleChangeUser(e) {
    let account = this.state.account
    account.members[0].user[e.target.name] = e.target.value
    this.setState({ account, error: null })
  }

  render() {
    let {
      show,
      account,
    } = this.state

    let title = account.members[0].user?.id ? "Edit Account" : "Add Account"
    let buttonTitle = account.members[0].user?.id ? "Save Changes" : "Add Account"

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-70"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form id="kt_modal_add_user_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll"
                data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header"
                data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px"
                style={{maxHeight: 458}} bis_skin_checked="1"
              >
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Company Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Name"
                    value={account.name}
                    onChange={e => {
                      account.name = e.target.value
                      this.setState({account})
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="First Name"
                    value={account.members[0].user?.first_name}
                    onChange={e => this._handleChangeUser(e)}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Last Name"
                    value={account.members[0].user?.last_name}
                    onChange={e => this._handleChangeUser(e)}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="example@domain.com"
                    value={account.members[0].user?.email}
                    onChange={e => this._handleChangeUser(e)}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder=""
                    value={account.members[0].user?.password}
                    onChange={e => this._handleChangeUser(e)}
                  />
                </div>
              </div>
              <div className="text-center pt-15" bis_skin_checked="1">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => this.props.onHide()}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  onClick={e => {
                    e.preventDefault()
                    this._handleSubmission()
                  }}
                >
                  <span className="indicator-label">{buttonTitle}</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
              <div bis_skin_checked="1"></div>
            </form>
          </Modal.Body>

        </Modal>
      </>
    )
  }

}